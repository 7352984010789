<script>
import NgText from "./NgText";

export default {
  name: "NgNumber",
  mixins: [NgText],
  created() {
    this.mask = "[^0-9.]+";
  },
  methods: {
    getsType() {
      return "number";
    },
    inputEvt() {
      if (this.mask) {
        const mask = this.mask;
        const patt = new RegExp(mask);
        this.$nextTick().then(() => {
          if (this.value && patt.test(this.value)) {
            const val = this.value.replace(patt, "");
            this.$emit("input", val);
          }
        });
      }
    },
  },
};
</script>
