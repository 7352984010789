<template>
  <div class="d-flex">
    <validation-provider
      v-slot="{ errors }"
      :name="label"
      :rules="rules"
      style="width: 100%"
    >
      <v-select
        :value="value"
        v-bind="{ ...config, ...$attrs }"
        multiple
        :items="items"
        :label="$t(label) + labelRequired()"
        :error-messages="errors"
        :value-comparator="compare"
        item-text="name"
        v-on="$listeners"
        @input="inputEvt"
        return-object
      >
        <!--template v-slot:item="{ item }">
          <span v-if="item.name">{{ item.name }}</span>
          <span v-else>{{ item.nombre }}</span>
        </template>
        <template v-slot:selection="{ item }">
          <span v-if="item.name">{{ item.name }}</span>
          <span v-else>{{ item.nombre }}</span>, 
        </template-->
      </v-select>
    </validation-provider>
    <slot></slot>
    <ng-help v-if="help" :text="help" />
  </div>
</template>
<script>
import NgSelect from "./NgSelect";
export default {
  name: "NgSelectMulti",
  mixins: [NgSelect],
};
</script>
