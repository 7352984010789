<template>
    <div class="pa-0">
      <div  class="print-component">
        <div ref="printRef" :key="keyRender" v-if="$store.state.shared.printData">           
          <component
            :is="getComponent()" :info="info"
            :key="keyRender"
          ></component>
        </div>
      </div>
    </div>

</template>
<script>

import isElectron from 'is-electron';
import PrintManager from '../../plugins/print-manager';

export default {
    name: 'print-component',
    components: { isElectron },
    mixins: [],
    data(){
      return {
        keyRender: 0,
        info: null,
        tmpl: "<div>{{info.name}}</div>",
        printManager: null
      }
    },
    watch: {
        '$store.state.shared.printData': {
            deep: true,
            handler: function(newData, old){
                if(newData){
                  this.info = newData.info;
                  this.tmpl = newData.tmpl;
                  this.keyRender = this.keyRender + 1;
                  setTimeout(() => {
                    this.print();
                  }, 250)
                  
                }
            }
        } 
    },
    mounted(){
     // this.printManager = new PrintManager();
    },
    methods: {
      print(){        
        try {
          var printContents = this.$refs.printRef.innerHTML;      
          let html = `${this.printManager.getHeader()}${this.printManager.cleanHtml(printContents)}${this.printManager.getFooter()}`;                
          if(isElectron()){
          } else {
            this.printManager.printBrowser(html);
          }          
        } catch (e) {
          console.log(e);
          return "";
        }
      },
      getComponent() {
        return {
          data() {
            return {
              //info: this.info
            };
          },
          props: ['info'],
          template: '<div v-if="info">' + this.tmpl + '</div>',
        };
      },
    }
  }
</script>
<style>
.print-component {
    display: none;
  }
@media print {
  .print-component {
    display: block;
  }
}
</style>
