<template>
  <div class="help-icon">
    <v-btn icon @click="dialog = true">
      <v-icon color="green">mdi-help-circle-outline </v-icon>
    </v-btn>
    <v-dialog v-model="dialog" width="500">

      <v-card class="pt-4">
        <v-card-text v-html="text">

        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: 'NgHelp',
  data() {
    return {
      dialog: false
    }
  },
  props: ['text']

}
</script>
