<template>
  <v-dialog v-model="dialog" width="500">
    <v-card>
      <v-card-title class="justify-center"> Horarios de Atención </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            cols="6"
            v-for="(item, index) in schedules"
            :key="'card_' + index"
          >
            <v-card outlined elevation="0" class="text-center">
              <v-card-title class="justify-center pa-2 grey lighten-4">
                {{ getDayOfWeek(index) }}
              </v-card-title>
              <v-card-text class="grey lighten-5 pt-3 pb-3">
                <p v-if="!isSameTime(item[0], item[1])">
                  <v-chip label color="primary"
                    ><v-icon left size="small">mdi-clock</v-icon
                    >{{ item[0] }}</v-chip
                  >
                  -
                  <v-chip label color="primary"
                    ><v-icon left size="small">mdi-clock</v-icon
                    >{{ item[1] }}</v-chip
                  >
                </p>
                <v-divider></v-divider>
                <p class="my-2" v-if="!isSameTime(item[2], item[3])">
                  <v-chip label color="primary"
                    ><v-icon left size="small">mdi-clock</v-icon
                    >{{ item[2] }}</v-chip
                  >
                  -
                  <v-chip label color="primary"
                    ><v-icon left size="small">mdi-clock</v-icon
                    >{{ item[3] }}</v-chip
                  >
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pb-3">
        <v-btn block @click="dialog = false" color="primary">Cerrar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      dialog: false,
    };
  },
  props: ["schedules"],
  methods: {
    renderItem() {
      this.dialog = true;
    },
    isSameTime(time1, time2) {
      return time1 === time2;
    },
    getDayOfWeek($numberDay) {
      switch ($numberDay) {
        case 0:
          return "Lunes";
        case 1:
          return "Martes";
        case 2:
          return "Miercoles";
        case 3:
          return "Jueves";
        case 4:
          return "Viernes";
        case 5:
          return "Sabado";
      }
    },
  },
};
</script>