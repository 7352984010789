<template>
  <div class="d-flex">
    <validation-provider
      v-slot="{ errors }"
      :name="label"
      :rules="rules"
      style="width: 100%"
    >
      <v-select
        :value="value"
        v-bind="{ ...config, ...$attrs }"
        :items="items"
        :error-messages="errors"
        @input="inputEvt"
        v-on="$listeners"
        :label="$t(label) + labelRequired()"
        :item-text="'name'"
        :value-comparator="compare"
        return-object
      >
      </v-select>
    </validation-provider>
    <ng-help v-if="help" :text="help" />
  </div>
</template>
<script>
import AbstractField from "./AbstractField";
import isElectron from 'is-electron';
// import { ipcRenderer } from 'electron';

export default {
  name: "NgSelectElectronPrinters",
  mixins: [AbstractField],
  components: { isElectron },
  data() {
    return {
      items: [],
      loading: false
    };
  },
  created() {
    //this.items = this.options.items;    
  },
  computed: {
    itemsList() {
      return this.items;
    },
  },
  mounted() {
    this.loadItems();
  },
  methods:{
    loadItems() {
        try {
          
              
            } catch(err) {
              console.error(err);
            } 
    },
  }
};
</script>
