<template>
  <div class="d-flex">
    <validation-provider
      v-slot="{ errors }"
      :name="label"
      :rules="rules"
      style="width: 100%"
    >
      <v-text-field
        :value="maskCurrency"
        v-bind="{ ...config, ...$attrs }"
        prepend-inner-icon="mdi-cash"
        :label="$t(label) + labelRequired()"
        v-on="$listeners"
        @input="inputEvt"
        type="text"
        :error-messages="errors"
      />
    </validation-provider>
    <ng-help v-if="help" :text="help" />
  </div>
</template>
<script>
import currency from "currency.js";
import AbstractField from "./AbstractField";
export default {
  name: "NgMoney",
  mixins: [AbstractField],
  data() {
    return {
      keyRender: 1,
    };
  },
  created() {
    this.mask = "[^0-9.,]+";
  },
  computed: {
    maskCurrency: {
      cache: false,
      get: function () {
        return currency(this.value + "", {
          precision: 0,
          symbol: "",
        }).format();
      },
      set: function (nv) {
        //this.value = ("" + nv).replace(/,/g, "");
      },
    },
  },
  methods: {
    inputEvt(e) {
      this.$nextTick().then(() => {
        if (!e) {
          e = "";
          this.$emit("input", e);
        } else {
          this.$emit("input", ("" + e).replace(/,/g, ""));
        }
      });
    },
  },
};
</script>
