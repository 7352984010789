<template>
  <span style="width: 100%">
    <div id="div-auto-person_provider" class="d-flex">
      <div
        class="v-input v-input--is-label-active v-input--is-dirty v-input--dense theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined select-person_provider"
      >
        <div class="v-input__control">
          <div
            role="combobox"
            aria-haspopup="listbox"
            aria-expanded="false"
            aria-owns="list-1682"
            class="v-input__slot"
          >
            <fieldset aria-hidden="true">
              <legend style="width: 65.25px">
                <span class="notranslate">​</span>
              </legend>
            </fieldset>
            <div class="v-select__slot">
              <label
                for="input-auto-person_provider"
                class="v-label v-label--active theme--light"
                style="left: 0px; right: auto; position: absolute"
                >{{ $t(label) }}  </label
              >
              <div class="v-select__selections">
                <span>{{ value }} </span><span v-if="options"> &nbsp;- {{$t(options)}}</span>
              </div>
              <div class="v-input__append-inner"></div>
            </div>
            <div class="v-menu"><!----></div>
          </div>
        </div>
      </div>
      <!----><!---->
    </div>
  </span>
</template>
<style scoped>
.v-select__selections{
  overflow: hidden;
}
</style>
<script>
import AbstractField from "./AbstractField";
export default {
  name: "NgLabel",
  mixins: [AbstractField],
};
</script>
