<template>
  <div>
    <div class="d-flex">
      <v-text-field
        v-model="value"
        v-bind="{ ...config, ...$attrs }"
        type="date"
        prepend-inner-icon="mdi-calendar"
        :label="$t(options.label) + labelRequired()"
        :error="error"
        :error-messages="errorMessages"
      ></v-text-field>

      <ng-help v-if="options.help" :text="options.help" />
    </div>
  </div>
</template>
<script>
import AbstractField from "./AbstractField";
import * as moment from "moment-timezone";

export default {
  name: "NgDateText",
  mixins: [AbstractField],
  data: () => ({
    date: "", //new Date().toISOString().substr(0, 10),
    year: "",
    month: "",
    day: "",
    menu: false,
    modal: false,
    max: null,
  }),
  created() {
    //this.$refs.picker.activePicker = 'YEAR';
    if (this.options.birthday) {
      this.max = new Date().toISOString().substr(0, 10);
    }
    if (this.options.current) {
      this.value = new Date().toISOString().substr(0, 10);
    }
  },
  methods: {
    nextFocus(field) {
      this.$refs[field].focus();
    },
    save(evt) {
      this.form[this.options.name] = evt;
      this.$emit("change", evt);
    },
    dateFormat(str) {
      if (str && str != null) {
        // return moment.tz(str, "America/Bogota").utc(); // .format("MMM D/ YYYY");
        // return moment(str).utc();
        return moment.tz(str, "America/Bogota").utc().format("YYYY-MM-DD");
      }
      return "";
    },
  },
  computed: {
    value: {
      cache: false,
      get: function () {
        if (this.options) {
          return this.dateFormat(this.form[this.options.name]);
        }
        return null;
      },
      set: function (nv) {
        this.form[this.options.name] = this.dateFormat(nv);
      },
    },
  },
  watch: {
 
    menu(val) {
      if (val && this.config.activePicker) {
        setTimeout(() => {
          this.$refs.picker.activePicker = this.config.activePicker; //'YEAR'
        });
      }
    },
  },
};
</script>
