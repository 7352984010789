<template>
  <div class="d-flex">
    <!-- return-object -->
    <input type="hidden" :value="value" />
    <v-select
      v-model="valueSelect"
      v-bind="{ ...config, ...$attrs }"
      :items="items"
      @input="input"

      :label="$t(label) + labelRequired()"
      item-text="name"
      item-value="id"
      
      :value-comparator="compare"
    >
      <template v-slot:item="{ item }">
        <span v-if="item.name">{{ $t(item.name) }}</span>
        <span v-else>{{ $t(item.nombre) }}</span>
      </template>
      <template v-slot:selection="{ item }">
        <span v-if="item.name">{{ $t(item.name) }}</span>
        <span v-else>{{ $t(item.nombre) }}</span>
      </template>
    </v-select>
    <ng-help v-if="help" :text="help" />
  </div>
</template>
<script>
import AbstractField from "./AbstractField";
export default {
  name: "NgBooleanFilter",
  mixins: [AbstractField],
  data() {
    return {
      valueSelect: null,
      items: [],
    };
  },
  created() {
    this.items = [
      { id: 1, name: "Todos" },
      { id: 2, name: "Sí" },
      { id: 3, name: "No" },
    ];
  },
  mounted() {
   // this.valueSelect = this.getSelectValue(this.form[this.options.name]);
  },
  methods: {
    input(evt){
      const val = this.getBooleanValue(evt);
      this.$emit('input', val);
    },
    getSelectValue(val) {
      if (val === "" || val === null) {
        return { id: 1, name: "Todos" };
      }
      if (val == true) {
        return { id: 2, name: "Sí" };
      }
      if (val == false) {
        return { id: 3, name: "No" };
      }
    },
    getBooleanValue(val) {
      if (val == 1) {
        return null;
      }
      if (val == 2) {
        return 'true';
      }
      if (val == 3) {
        return 'false';
      }
    },
    change(evt) {
      //this.form[this.options.name] = this.getBooleanValue(evt);
    },
  },
};
</script>
