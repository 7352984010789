<template>
  <div v-html="html" style="padding: 10px" v-bind="{...config, ...$attrs}"></div>
</template>
<script>
  // import AbstractField from './AbstractField'
  export default {
    name: 'NgBlockHtml',
    //mixins: [AbstractField]
   props: {
   },
  }
</script>
