<template>
      <v-dialog v-model="dialog" :light="true" :dark="false"   persistent max-width="290">
      <v-card>
        <v-card-title class="headline"></v-card-title>
        <v-card-text>{{text}}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="close(false)" text >{{$t('Cancelar')}}</v-btn>
          <v-btn color="primary" @click="close(true)" text >{{$t('Aceptar')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>
  
  export default {
    data(){
        return {
            dialog: true
        }
    },
    props: ['text', 'resolve'],
    methods: {
      close(accept){
          this.resolve(accept);
          this.$destroy(this.$el)
      }  
    }
  }
</script>
