<template>
  <div>
    <div v-for="(f, index) of getFields(json)" :key="index">
      <div v-if="isArray(f[1])" class="json-array">
         <b>{{$t(f[0])}}</b>:
        <span >({{f[1].length}}) 
          <v-btn v-if="expand" small icon @click="expand = false"> <v-icon>mdi-minus-box-outline</v-icon> </v-btn>
          <v-btn v-else small icon @click="expand = true"> <v-icon>mdi-plus-box-outline</v-icon> </v-btn> </span>
        <div v-if="expand" class="pl-3">
          <div v-for="(obj, index) in f[1]" :key="index">
              <div style="display: flex;margin-top: 5px">
                <div style="width: 30px">{{index+1}}</div>

                <div><ng-json-pretty  :json="obj" show-expand></ng-json-pretty></div>
              </div>
            

          </div>
        </div>
      </div>
      <div v-else-if="isObject(f[1])" class="json-object">
        <b>{{$t(f[0])}}</b>:
        <span v-if="f[1].name">{{f[1].name}}</span>
        <div v-else>
          <ng-json-pretty :json="f[1]"></ng-json-pretty>
        </div>
        
      </div>
      <div v-else  class="json-field">
        <b>{{$t(f[0])}}</b>: {{f[1]}}
      </div>
    </div>
  
  </div>
</template>
<script>
//import VueJsonPretty from 'vue-json-pretty';
//import 'vue-json-pretty/lib/styles.css';

  export default {
    name: 'NgJsonPretty',
    components: {  },
    data(){
      return {
        logs: [],
        expand: false
      }
    },
    props: ['json', 'showExpand'],
    methods: {
     getFields(json){
      return Object.entries(json)
     },
     isArray(obj){
      return Array.isArray(obj);
     },
     isObject(obj){
      return obj && typeof obj == 'object';
     }
    }
 
  }
</script>
<style>
.json-field{
  /*height: 25px;*/

}
.json-object{
  /*height: 25px;*/

}
.json-array{
  /*height: 25px;*/
}
</style>
