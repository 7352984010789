export default {
  data() {
    return {
      rulesDefault: [],
      config: {},
      mask: null,
      defaultConfig: {
        filled: false,
        flat: false,
        outlined: true,
        color: 'success',
        // hint: 'For example, flowers or used cars',
        // 'prepend-inner-icon': 'mdi-map-marker',
        // 'append-icon': 'mdi-map-marker',
        clearable: true,
        // label: 'Field',
        //'hide-details': 'auto',
        //'validate-on-blur': true,
        //maxlength: 1024,
        //minlength: 0,
        placeholder: '',
        dark: false,
        dense: true,
        //name: 'field1',
        //upper: false,
        //style: { width: '100%'}
        //size: 12
      }
    }
  },
  inheritAttrs: false,
  props: ['value', 'options', 'rules', 'label', 'help'],
  mounted() {
    if (!this.$el.style.width) {
      this.$el.style.width = '100%';
    }
  },
  created() {
    this.config = Object.assign(this.defaultConfig)
  },
  methods: {
    inputEvt(evt){
      this.$emit('input', evt);
    },
    labelRequired() {
      if(this.rules){
        if(this.rules.required){
          return '*';
        }
      }
      return ''
    },
    compare(a, b) {
      if (a && b) {
        if (a.id && b.id && a.id === b.id) {
          return true
        }
        if (a === b) {
          return true
        }
        if (a.id == b) {
          return true;
        }
      }
      return false
    },
    serializeQuery(params) {
      const value = {};
      for (const i in params) {
        if (Array.isArray(params[i])) {
          value[i] = params[i].map((item) => {
            if (item.id) {
              return { id: item.id };
            }
            return item;
          });
        } else {
          if (params[i] && params[i].id) {
            value[i] = { id: params[i].id };
          } else {
            value[i] = params[i];
          }
        }
      }
      return "params=" + JSON.stringify( value );
    },
    getUrlQuery(params) {
      return this.url; // + '?' + this.serializeQuery(params);
    }
  }
}
