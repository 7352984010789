<template>
  <!--   v-bind="$attrs" :permanent="!$store.getters.mobile"  -->
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :src="barImage"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    mobile-breakpoint="600"
    :permanent="!$store.getters.mobile"
    app
  >
    <template v-slot:img="props">
      <v-img :gradient="`to bottom, ${barColor}`" v-bind="props" />
    </template>

    <v-divider class="mb-1" />

    <v-list dense nav v-if="$store.getters.user">
      <v-list-item class="d-flex justify-center" dense>
        <!--v-list-item-avatar color="white" contain-->
        <img
          :src="$http.getBaseUrlPublicFiles() + $store.getters.company.logo"
          :alt="$store.getters.company.name"
          style="width: auto; height: 64px"
          v-if="$store.getters.company.logo"
        />
        <!--/v-list-item-avatar-->
      </v-list-item>
      <v-list-item to="/admin/config/company">
        <v-list-item-content>
          <v-list-item-title class="text-center">
            {{ $store.getters.company.name}}
          </v-list-item-title>
          <!--v-list-item-title
            class="display-1 text-center"
            v-text="$currentUser.data.sub"
          /-->
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-center" v-text="$store.getters.user.name"/>
          <!--v-list-item-title
            class="text-center"
            v-text="$currentUser.data.group.name"
          /-->
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider class="mb-2" />

    <v-list class="drawer-menu" expand nav dense>
      <div>

      <template v-for="item in menu">
        <v-list-item
          :key="item.id"
          v-if="
            item &&
            (!item.children || (item.children && item.children.length == 0))
          "
          dense
          style="color: white !important"
          :to="item.path"
        >
          <v-list-item-title dense>
            <v-icon>mdi-apps</v-icon> {{ item.name }}
          </v-list-item-title>
        </v-list-item>

        <template v-else>
          <v-list-group style="color: white !important" dense :key="item.id">
            <template v-slot:activator>
              <v-list-item-title style="color: white !important" dense>
                <v-icon>mdi-apps</v-icon> {{ item.name }}</v-list-item-title
              >
            </template>
            <template v-for="subitem in item.children">
              <v-list-group
                :key="subitem.id"
                no-action
                sub-group
                dense
                v-if="
                  subitem && subitem.children && subitem.children.length > 0
                "
                class="menu-nivel-2"
              >
                <template v-slot:activator style="padding-left: 0px !important">
                  <div style="color: white !important;" >
                  <v-list-item style="color: white !important;"  dense>
                    <v-list-item-content style="color: white !important"  dense>
                      <v-list-item-title style="color: white !important"  dense>
                        <v-icon v-if="subitem.icon">{{ subitem.icon }}</v-icon>
                        {{ subitem.name }}</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                  </div>
                </template>
                <v-list-item
                  v-for="(subitem2, i) in subitem.children"
                  :key="i"
                  dense
                  link
                  :to="subitem2.path"
                  class="pl-8"
                >
                  <v-list-item-title dense
                    ><v-icon v-if="subitem2.icon" class="ml-2">{{
                      subitem2.icon
                    }}</v-icon>
                    {{ subitem2.name }}
                  </v-list-item-title>
                </v-list-item>
              </v-list-group>
              <v-list-item :key="subitem.id" v-else style="color: white !important" dense :to="subitem.path" class="pl-4">
                <v-list-item-title dense>
                  <v-icon v-if="subitem.icon" class="ml-2 mr-1">
                    {{ subitem.icon }}
                  </v-icon>{{ subitem.name }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-list-group>
        </template>
      </template>




      <v-list-item @click="logout()" dense>
        <v-list-item-content dense>
          <v-list-item-title dense>
            <v-icon>mdi-logout</v-icon> Salir
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      </div>
    </v-list>


  </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapState } from "vuex";

export default {
  name: "LayoutAppDrawer",

  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    items: [],
  }),
  created() {
    //this.$auth.loadMenu();
    this.loadInfoSession();
    /*let auth = localStorage.getItem('auth')
      if (auth) {
        auth = JSON.parse(auth)
        this.$store.dispatch('autoSignInAuth', auth);
      }*/

  },
  computed: {
    ...mapState(["barColor", "barImage"]),
    drawer: {
      get() {
        return this.$store.getters.drawer;
      },
      set(val) {
        this.$store.commit("setDrawer", val);
      },
    },
    menu() {
      return this.$store.getters.menu;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("logoutAuth");
    },
    loadInfoSession() {
      if (!this.user) {
        //if(!this.menu){
        this.$auth.loadInfoSession();
        //}
      }
    },
  },
};
</script>
<style>

.v-application--is-ltr .v-list-group--sub-group .v-list-group__header {
    padding-left: 22px !important;
}
/*
.menu-nivel-2 .v-list-group--sub-group .v-list-group__header::before {
    padding-left: 22px !important;
}*/
.v-application--is-ltr .v-list-group--sub-group .v-list-item__action:first-child, .v-application--is-ltr .v-list-group--sub-group .v-list-item__avatar:first-child, .v-application--is-ltr .v-list-group--sub-group .v-list-item__icon:first-child {
    margin-right: 0px !important;
}
</style>