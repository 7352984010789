<template>
<div v-if="items">
    <input type="hidden" :value="value" />
    <div class="d-flex">
    <ng-text readonly :value="textValue" :label="label" @click:clear="clear"></ng-text>
    
    <v-btn icon @click="dialog = true"><v-icon>mdi-magnify</v-icon> </v-btn>
    </div>
    <!--v-radio-group :value="value" v-if="items" v-model="selected" -->
    <v-dialog
      v-model="dialog"
      width="500"
    >
    <div style="background-color: white" class="pa-3">
        <h4>{{$t('Seleccione una categoría')}}</h4>
    <v-treeview style="width: 100%"
      
      selection-type="leaf"
      :active.sync="actives"
      open-all
       activatable
       return-object
      :items="items"
    >
      <template v-slot:prepend="{ active }"> 
                    <v-icon color="primary" v-if="active">mdi-check</v-icon>
        </template>
        
    </v-treeview>
    <div class="text-right">
        <v-btn @click="dialog=false">{{$t('Cerrar')}}</v-btn>
        <v-btn @click="save" color="primary">{{$t('Aceptar')}}</v-btn>
    </div>
    </div>
    </v-dialog>
  <!--/v-radio-group-->
</div>
</template>
<script>

export default {
  data: () => ({
    actives: [],
    dialog: false,
    items: null,
  }),
  props: ['value', 'url', 'label', 'cacheOff', 'dependsOf'],
  computed:{
      textValue(){
          if(this.value && this.value.name){
              return this.value.name;
          }
          return '';
      }
  },
  watch: {
      value: {
          deep: true,
          handler(){
              if(this.value){
                  this.actives = [this.value];
              }
          }
      }
  },
  created() {
    this.loadItems();
  },
  methods: {
    clear(){
      this.$emit('input', null);
    },
    save(){
        if(this.actives && this.actives.length == 0){
            this.clear();
        }else{
            this.$emit('input', { id: this.actives[0].id, name: this.actives[0].name });
        }
        this.dialog = false;
    },
    reload() {
      this.$nextTick().then(() => {
        this.loadItems();
      });
    },
    loadItems() {
      const params = {};
      if (this.dependsOf) {
        for (const index in this.dependsOf) {
          params[index] = this.dependsOf[index];
        }
      }

      let list = this.$store.getters.getList(this.getUrlQuery(params));
      if (!list && !this.cacheOff) {
        this.queryOptions();
      } else {
        this.items = list.items;
      }
    },
    serializeQuery(params) {
      const value = {};
      for (const i in params) {
        if (Array.isArray(params[i])) {
          value[i] = params[i].map((item) => {
            if (item.id) {
              return { id: item.id };
            }
            return item;
          });
        } else {
          if (params[i] && params[i].id) {
            value[i] = { id: params[i].id };
          } else {
            value[i] = params[i];
          }
        }
      }
      return "params=" + JSON.stringify( value );
    },
    getUrlQuery(params) {
      return this.url + '?' + this.serializeQuery(params);
    },
    queryOptions(v) {      
      const params = {};
      if (this.dependsOf) {
        for (const index in this.dependsOf) {
          params[index] = this.dependsOf[index];
          if (!params[index]) {
            this.loading = false;
            return;
          }
        }
      }
      this.loading = true;
      this.$http
        .get(this.getUrlQuery(params))
        .then((data) => {
          this.$store.commit("addList", {
            path: this.getUrlQuery(params),
            items: data.items,
          });
          this.$nextTick().then(() => {
            this.loading = false;
            this.items = data.items;
          });
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
</script>
  