<template>
  <div class="d-flex">
    <validation-provider
      v-slot="{ errors }"
      :name="$t(label)"
      :rules="rules"
      style="width: 100%"
    >
      <v-text-field
        :value="value"
        v-bind="{ ...config, ...$attrs }"
        :error-messages="errors"
        :label="$t(label) + labelRequired()"
        :type="getType()"
        v-on="$listeners"
        @input="inputEvt"
      />
    </validation-provider>
    <ng-help v-if="help" :text="help" />
  </div>
</template>
<script>
import AbstractField from "./AbstractField";

export default {
  name: "NgText",
  mixins: [AbstractField],
  mounted() {
  },
  methods: {
    getType() {
      /*if (this.options.type) {
        return this.options.type;
      }*/
      return "text";
    },
    inputEvt(evt) {
      if (this.value && typeof this.value == "string") {
        if (this.mask) {
          const mask = this.mask;
          const patt = new RegExp(mask);

          this.$nextTick().then(() => {
            if (this.value && patt.test(this.value)) {
              const val = this.value.replace(patt, "");
              this.$emit("input", val);
            }
          });
        }
      }else{
        this.$emit("input", evt);
      }
    },
    onKeypress() {
      //const ignore = [113, 8, 46, 9, 35, 36, 37, 38, 39, 40, 45, 20, 16]
      /* if (ignore.indexOf(event.keyCode) > -1) {
          return true
        }*/
      //event.preventDefault()
      // event.stop();
      return true;
    },
  },
};
</script>
