<template>
  <div class="help-icon-module">
    <a :href="'https://help.source.co/' + getUrlHelp()" target="_blank">
      <v-icon color="green">mdi-help-circle-outline </v-icon>
    </a>
  </div>
</template>
<script>
export default {
  name: 'NgHelp',
  data() {
    return {
      dialog: false
    }
  },
  props: ['path'],
  methods: {
    getUrlHelp() {
      return this.path;
    }
  }

}
</script>
