<template>
<div>
  <div v-if="messages && messages.length > 0" >
      <div class="module-errors">
        {{$t('Existen campos con errores')}}
        <ul>
            <li v-for="(m, i) in messages" :key="i">
                {{m}}
            </li>
        </ul>
      </div>
  </div>
</div>
</template>

<script>
export default {
  name: "default-errors",
  mounted() {},
  props: ["errors"],
  computed:{
      messages(){
        if(this.errors){
            const errors = [];
            for(const i in this.errors){
                if(this.errors[i].length > 0){
                    errors.push(this.errors[i][0]);
                }
            }
            return errors;
        }
        return null;
      }
  }

};
</script>
<style scoped>
    .module-errors {
        border: 1px solid red;
        padding: 6px;
        border-radius: 4px;
    }
    .module-errors ul li {
        color: red;
        font-size: 0.8em;
    }
</style>