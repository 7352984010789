<template>
  <v-dialog v-model="dialog" max-width="500" persisent>
    <v-card>
      <v-card-title class="d-flex justify-space-between">
        <div class="primary--text">Nubempresa</div>
        <div class="body-2">Llamada entrante
          <v-btn icon small @click="rejectCall"><v-icon small>mdi-close</v-icon></v-btn>
        </div>
      </v-card-title>
      <v-divider />
      <v-card-text class="text-center pa-5">
        <v-img src="/assets/images/brand/primary-vertical-logo.svg" max-width="150" class="mx-auto"></v-img>
        <span>esta intentando contactar contigo.</span>
      </v-card-text>
      <v-card-actions class="d-flex">
        <v-btn @click="rejectCall" color="red" dark large class="flex-grow-1"><v-icon left>mdi-phone-hangup</v-icon>
          Rechazar </v-btn>
        <v-btn @click="acceptCall" color="green" dark large class="flex-grow-1"><v-icon left>mdi-phone</v-icon> Aceptar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    renderItem() {
      this.dialog = true;
    },
    acceptCall() {
      this.$emit("confirm:call", true);
      this.dialog = false;
    },
    rejectCall() {
      this.$emit("confirm:call", false);
      this.dialog = false;
    },
    closeModal() {
      this.dialog = false;
    },
  },
};
</script>
<style scoped>
.v-dialog__content {
  z-index: 9999 !important;
}
</style>
