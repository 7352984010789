<template>
  <div class="d-flex">
    <v-text-field
      ref="autocomplete"
      v-model="value"
      v-bind="{ ...config, ...$attrs }"
      :id="id"
      autocomplete="off"
      hide-details="auto"
      :label="$t(label) + labelRequired()"
      :error="error"
      :error-messages="errorMessages"
    />

    <ng-help v-if="help" :text="help" />
  </div>
</template>
<script>
import AbstractField from "./AbstractField";
export default {
  name: "NgCityGoogle",
  mixins: [AbstractField],
  data() {
    return {
      id: null,
    };
  },
  computed: {
    /*value: {
      cache: false,
      get: function () {
        if (this.options) {
          if (this.form[this.options.name]) {
            return this.form[this.options.name].city;
          } else {
            return this.form[this.options.name];
          }
        }
        return null;
      },
      set: function (nv) {
        if (this.options.upper) {
          if (nv) {
            this.form[this.options.name] = nv.toUpperCase();
          }
        } else {
          this.form[this.options.name] = nv;
        }
      },
    },*/
  },
  mounted() {
    /* this.id = 'gm_'+(new Date()).getTime();
      setTimeout(() => {
        this.autocomplete = new google.maps.places.Autocomplete(
          (document.getElementById(this.id)),
          { //types: ['locality', 'sublocality', 'postal_code', 'country',
            //'administrative_area_level_1', 'administrative_area_level_2']
         }
        );
        this.autocomplete.setFields(['formatted_address', 'address_components']);

        this.autocomplete.addListener('place_changed', () => {
          let place = this.autocomplete.getPlace();
  
          let info = this.getInfoFromPlace(place);
          this.$emit('change:city', info);
          this.value = info;
          //place.formatted_address;

        });
      }, 100);  */
  },
  methods: {
    getInfoFromPlace(place) {
      const info = {};
      if (place.address_components) {
        for (const comp of place.address_components) {
          if (this.isCountry(comp)) {
            info["country"] = { name: comp.long_name, short: comp.short_name };
          }
          if (this.isState(comp)) {
            info["state"] = comp.long_name;
          }
          if (this.isCity(comp)) {
            info["city"] = comp.long_name;
          }
        }
      }
      return info;
    },
    isCity(component) {
      if (
        component.types.indexOf("administrative_area_level_2") >= 0 ||
        component.types.indexOf("locality") >= 0
      ) {
        return true;
      }
      return false;
    },
    isState(component) {
      if (component.types.indexOf("administrative_area_level_1") >= 0) {
        return true;
      }
      return false;
    },
    isCountry(component) {
      if (component.types.indexOf("country") >= 0) {
        return true;
      }
      return false;
    },
  },
};
</script>
