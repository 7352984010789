class HttpClient {
  constructor(client, store) {

    this.client = client;
    this.store = store;
  }

  getToken() {
    let token = localStorage.getItem('source_tk');
    if (token) {
      return token;
    }
    return null;
  }
  getTokenId() {
    let token = localStorage.getItem('tenant');
    if (token) {
      return token;
    }
    return null;
  }

  getHeaders() {
    //const tk = this.getToken();
    const tk = this.getToken();
    const tkId = this.getTokenId();
    let headers = {}
    if (tk) {
      headers = { 'Authorization': "Bearer " + tk, /*"Access-Control-Allow-Origin": "*", mode: 'cors' */ /*, 'authcmp': '6b49f698-01c9-65BB1B8997FAE21476E81EDADB38A'*/ };
    }
    if (tkId) {
      headers['x-tenant'] = tkId;
    }
    return headers;
  }

  getBaseUrl() {
    return process.env.VUE_APP_BASECONFIG_URL;
  }

  getBaseUrlImagesCategoryProducts() {
    return process.env.VUE_APP_BASECONFIG_URL_CATEGORY;
  }

  getBaseUrlImagesNews() {
    return process.env.VUE_APP_BASECONFIG_URL_NEWS;
  }

  getBaseUrlImagesProducts() {
    return process.env.VUE_APP_BASECONFIG_URL_PRODUCTS;
  }

  getBaseUrlPublicFiles() {
    return process.env.VUE_APP_BASECONFIG_URL_PUBLIC_FILES;
  }

  put(path, params, loading, showMsgSuccess) {
    const config = { headers: this.getHeaders() };
    return new Promise((resolve, reject) => {
      if (this.store && loading) {
        this.store.commit('setLoading', true);
      }
      let url = process.env.VUE_APP_BASECONFIG_URL + path;
      if (path.indexOf('https') >= 0) {
        url = path;
      }
      this.client.post(url, params, config).then(response => {
        this.removeLoading();
        if (response.data.success > 0) {
          if (showMsgSuccess) {
            this.showMsg(response.data.msg);
          }
          resolve(response.data);
        } else {
          if (this.store && response.data.msg) {
            this.showError(response.data.msg);
          } else {
            this.showError('Ha ocurrido un error');
          }
          resolve(response.data);
        }
      }).catch(e => {
        this.removeLoading();
        this.showError( 'Ha ocurrido un error');
        reject(e)
      });
    })
  }

  post(path, params, loading, showMsgSuccess) {
    const config = { headers: this.getHeaders() };
    return new Promise((resolve, reject) => {
      if (this.store && loading) {
        this.store.commit('setLoading', true);
      }
      let url = process.env.VUE_APP_BASECONFIG_URL + path;
      if (path.indexOf('https') >= 0) {
        url = path;
      }
      this.client.post(url, params, config).then(response => {
        this.removeLoading();
        //throw new Error('jijijiji')
        if (response.data.success > 0) {
          if (showMsgSuccess) {
            this.showMsg(response.data.msg);
          }
          resolve(response.data);
        } else {
          if (this.store && response.data.msg) {
            if (showMsgSuccess) {
              this.showError(response.data.msg);
            }
          } /*else {
            this.showError('Ha ocurrido un error');
          }*/
          resolve(response.data);
        }
      }).catch(e => {
        this.removeLoading();
        if(showMsgSuccess){
          this.showError( 'Ha ocurrido un error');
        }
        reject(e)
      });
    })
  }

 /* postUrl(url, params){
    return this.client.post('http://localhost:5777/'+url, params);
  }

  getUrl(url, params){
    return this.client.get('http://localhost:5777/'+url);
  }*/

  postBasic(path, params) {
    const config = { headers: this.getHeaders() };
    return this.client.post(process.env.VUE_APP_BASECONFIG_URL + path, params, config);
  }

  queryReport(path, params) {
    const config = { headers: this.getHeaders() };
    return this.client.post(process.env.VUE_APP_BASECONFIG_URL_REPORT + path, params, config);
  }

  getRaw(path, loading, showMsgSuccess) {
    const config = { headers: this.getHeaders()/*, data: params*/ };
    return new Promise((resolve, reject) => {
      if (this.store && loading) {
        this.store.commit('setLoading', true);
      }
      let url = process.env.VUE_APP_BASECONFIG_URL + path;
      if (path.indexOf('https') >= 0) {
        url = path;
      }
      this.client.get(url, config).then(response => {
        this.removeLoading();
          resolve(response.data);
     
      }).catch(e => {
        this.removeLoading();
        this.showError( 'Ha ocurrido un error');
        reject(e)
      });
    })
  }
  serializeQuery(params) {
    const value = {};
    for (const i in params) {
      if (Array.isArray(params[i])) {
        value[i] = params[i].map((item) => {
          if (item.id) {
            return { id: item.id };
          }
          return item;
        });
      } else {
        if (params[i] && params[i].id) {
          value[i] = { id: params[i].id };
        } else {
          value[i] = params[i];
        }
      }
    }
    return "params=" + JSON.stringify(value);
    //return new URLSearchParams(value).toString()
  }
  get(path, params, loading, showMsgSuccess) {
    const config = { headers: this.getHeaders()/*, data: params*/ };
    return new Promise((resolve, reject) => {
      if (this.store && loading) {
        this.store.commit('setLoading', true);
      }
      if(this.store && !this.store.getters.online){
        this.store.commit('setLoading', false);
        resolve({'success': false});
        return;
      }
      let url = process.env.VUE_APP_BASECONFIG_URL + path;
      if (path.indexOf('https') >= 0) {
        url = path;
      }
      if(params){
        if(url.indexOf('?') >= 0){
          url += '&' + this.serializeQuery(params);
        }else{
          url += '?' + this.serializeQuery(params);
        }
      }
      this.client.get(url, config).then(response => {
        this.removeLoading();
        if (response.data.success > 0) {
          if (showMsgSuccess) {
            this.store.commit('setMessage', response.data.msg);
          }
          resolve(response.data);
        } else {
          if (this.store && response.data.msg) {
            this.showError( response.data.msg);
          } else {
            this.showError( 'Ha ocurrido un error');
          }
          resolve(response.data);
        }
      }).catch(e => {
        this.removeLoading();
        this.showError( 'Ha ocurrido un error');
        reject(e)
      });
    })
  }

  delete(path, params, loading, showMsgSuccess) {
    const config = { headers: this.getHeaders() };
    return new Promise((resolve, reject) => {
      if (this.store && loading) {
        this.store.commit('setLoading', true);
      }
      let url = process.env.VUE_APP_BASECONFIG_URL + path;
      if (path.indexOf('https') >= 0) {
        url = path;
      }
      this.client.delete(url, config).then(response => {
        this.removeLoading();
        if (response.data.success > 0) {
          if (showMsgSuccess) {
            this.showMsg(response.data.msg);
          }
          resolve(response.data);
        } else {
          this.removeLoading();
          if (this.store && response.data.msg) {
            this.showError(response.data.msg);
          } else {
            this.showError( 'Ha ocurrido un error');
          }
          resolve(response.data);
        }
      }).catch(e => {
        this.removeLoading();
        this.showError( 'Ha ocurrido un error');
        reject(e)

      });
    })
  }
  showMsg(msg){
    if (this.store && msg) {
      this.store.commit('setMessage', msg);
    }
  }
  showError(msg){
    if (this.store && msg) {
      this.store.commit('setError', msg);
    }
  }
  removeLoading(){
    if (this.store) {
      this.store.commit('setLoading', false);
    }
  }

  upload(path, params) {
    const config = { headers: this.getHeaders() };
    config.headers['Content-Type'] = 'multipart/form-data;boundary=' + Math.random().toString().substr(2);
    return this.client.post(process.env.VUE_APP_BASECONFIG_URL + path, params, config);
  }
}
import axios from 'axios';

axios.interceptors.response.use(
  function(successRes) {
    if(successRes && successRes.data){
      if(successRes.data.event && successRes.data.event == 'killsession'){
        let sessKilled  = localStorage.getItem('sk');
        if(!sessKilled){
          localStorage.setItem('sk', 1);
           window.location.href = '/login' ;
        }
      }
    }
    return successRes;
  }, 
  function(error) {
    console.log(error);
    return Promise.reject(error);
  }
);

export default {
  install(Vue, options) {
    
    Vue.prototype.$http = new HttpClient(axios, options.store);
  }

}
