<template>
  <div class="d-flex keyboard_menu" v-if="isTouch">
    <v-menu
      v-model="openKeyBoard"
      :close-on-content-click="false"
      offset-y
      :nudge-top="nudgetTop"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-row class="ma-0 pa-0" v-bind="attrs" v-on="on">
          <validation-provider
            v-slot="{ errors }"
            :name="$t(label)"
            :rules="rules"
            style="width: 100%"
          >
            <v-text-field
              :value="value"
              v-bind="{ ...config, ...$attrs }"
              :error-messages="errors"
              :label="$t(label) + labelRequired()"
              :type="getType()"
              v-on="$listeners"
              @input="inputEvt"
              append-icon="mdi-keyboard"
              @click:append="openKeyboard()"
              :clearable="false"
              @click:clear="clear"
              
              
            />
          </validation-provider>
          <ng-help v-if="help" :text="help" />
        </v-row>
      </template>  
      <SimpleKeyboardNumeric
        :keyboardClass="
          'keyboard_number_' + replaceSpaces(label) + '_' + datetime
        "
        @onChange="onChange"
        @onKeyPress="onKeyPress"
        :input="valueKeyboard"
      />
      <!---->
    </v-menu>
  </div>
  <div class="d-flex" v-else>
    <validation-provider
      v-slot="{ errors }"
      :name="$t(label)"
      :rules="rules"
      style="width: 100%"
    >
      <v-text-field
        :value="value"
        v-bind="{ ...config, ...$attrs }"
        :error-messages="errors"
        :label="$t(label) + labelRequired()"
        :type="getType()"
        v-on="$listeners"
        @input="inputEvt"
        :clearable="false"
        @click:clear="clear"
      />
    </validation-provider>
  </div>
</template>
<style>
.simple-keyboard {
  padding: 10px !important;
}
</style>
<script>
import AbstractField from "./AbstractField";
import SimpleKeyboardNumeric from "./SimpleKeyboardNumeric";

export default {
  name: "NgNumberKeyboard",
  components: {
    SimpleKeyboardNumeric,
  },
  props: {
    nudgetTop: {
      default: 5,
      type: Number,
    },
  },
  data: () => ({
    openKeyBoard: false,
    valueKeyboard: "",
    isTouch: false,
    datetime: "",
  }),
  mixins: [AbstractField],
  created() {
    this.mask = "[^0-9.]+";
  },
  mounted() {
    this.datetime = new Date().getTime();
    this.isTouch = this.$store.getters.getPosValue("pos_activate_touchscreen");
  },
  methods: {
    getType() {
      return "text";
    },
    inputEvt() {
      if (this.mask) {
        const mask = this.mask;
        const patt = new RegExp(mask);
        this.$nextTick().then(() => {
          if (this.value && patt.test(this.value)) {
            const val = this.value.replace(patt, "");
            this.$emit("input", val);
          }
        });
      }
    },
    clear() {
      this.valueKeyboard = "";
      //this.value = "";
      this.$emit("clear", "");
      this.$emit("input", "");
    },
    replaceSpaces(string) {
      if (string) {
        return string.replace(/\s/g, "_").toLowerCase();
      }
      return "nmb";
    },
    openKeyboard() {
      this.openKeyBoard = true;
    },
    onChange(input) {
      //this.value = input;
      this.$emit("input", input);
    },
    onKeyPress(button) {},
    onInputChange(input) {
      this.$emit("input", input);
    },
  },
};
</script>
