import Vue from "vue";

let instance;

function authPlugin(store, router) {
  if (instance) return instance;

  instance = new Vue({
    data() {
      return {
        loading: true,
        isAuthenticated: false,
        user: {},
        version: '1.0',
        store: store,
        router: router,
        //auth0Client: null,
        //popupOpen: false,
        error: null
      };
    },
    methods: {
      debug(txt) {
        console.log(txt)
      },
      saveLocal(key, val){
        localStorage.setItem(key, val)
      },
      loadInfoSession() {
        return new Promise((resolve, reject) => {
          this.$http.post('api/v1/auth/info', { menu: 'pos' }, false, false).then((response) => {
            //console.log(response)
            if (response.success) {
              this.store.commit('setUser', response.profile);
              this.store.commit('setCompany', response.company);
              this.store.commit('setMenu', response.menu);

              localStorage.setItem('profile', JSON.stringify(response.profile));
              //this.router.push('/admin')
              resolve(response)
            }else{
              resolve({success: false});
            }
          }).catch(e => {
            console.log(e)
            reject(e)
          })
        })

      },
      authenticated(params) {

        return new Promise((resolve, reject) => {
          //console.log(params)
          this.$http.post('api/login', { ...params }, true, false).then((response) => {
            //console.log(response)
            if (response.success) {
              this.store.commit('setUser', response.profile);
              this.store.commit('setCompany', response.company);
              this.store.commit('setMenu', response.menu);
              this.saveLocal('source_tk', response.token);
              this.saveLocal('tenant', response.company.id);
              resolve(response); 
            }
            else {
              resolve(response);
            }
          }).catch(e => {
            console.log(e);
            reject(false); 
          })
        });
      },
      loadMenu() {
        //console.log(this)
        this.$http.get('api/auth/get-menu/main', {}, true, true).then((response) => {
          if (response.success) {
            this.store.commit('setMenu', response.menu);
          }
        }).catch(e => {
          console.log(e)
        })
      }
    }
  });
  return instance;
}

export default {

  install(Vue, options) {
    ///console.log(Vue)
    Vue.prototype.$auth = authPlugin(options.store, options.router);
    //console.log()
    //.registerModule('auth', Vue.prototype.$auth);
  },

  //constructor(){
  //this.http = http;
  //}
  /*authenticatedUserFirebase(user) {
    return new Promise((resolve, reject) => {
      this.http.post('api/v1/auth/authenticated-user-firebase', { data: user}).then( (data) => {
        // console.log({ user: res.data.data, token: res.data.token })
        if (data.success) {
          const auth = JSON.stringify(data.data);
          localStorage.setItem('auth', auth);
          localStorage.setItem('source_tk', data.token);
        }
        resolve(data);
      }).catch((e) => {
        reject(e);
      })
    });
  }
  authenticated(user, cmp) {
    return new Promise((resolve, reject) => {
      this.http.post('api/v1/auth/authenticated', { data: user}, cmp, true, true).then((data) => {
        // console.log({ user: res.data.data, token: res.data.token })
        if (data.success) {
          const auth = JSON.stringify(data.data);
          localStorage.setItem('auth', auth);
          localStorage.setItem('source_tk', data.token);
        }
        resolve(data);
      }).catch((e) => {
        reject(e);
      })
    });
  }*/
}

  //export default Auth;
