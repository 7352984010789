<template>
  <v-main class="">
    <router-view :key="$route.fullPath" />

    <!--layout-core-footer /-->
    <div>
    <print-component></print-component>
    </div>
  </v-main>
</template>
<style scoped>
.v-main{
  padding-top: 49px !important;
  background: #F1F0F6;
}
</style>

<script>
  export default {
    name: 'LayoutPosView',
  }
</script>
