
const equalsIgnoreOrder = (a, b) => {
	if (a.length !== b.length) return false;
	const uniqueValues = new Set([...a, ...b]);
	for (const v of uniqueValues) {
		const aCount = a.filter(e => e === v).length;
		const bCount = b.filter(e => e === v).length;
		if (aCount !== bCount) return false;
	}
	return true;
}

const containsAll = (a, b) => {
    const bSet = new Set(b);
    return a.every(element => bSet.has(element));
}

class LokiAdapter {
	constructor(db, store) {
		this.db = db;
		this.loaded = false;
		this.interval = null;
		this.store = store;
	}

	databaseInitialize() {
		console.info('[database] started...');
		this.loaded = true;
		this.createCollections();
		if (this.store && this.store.commit) {
			this.store.commit('loaded', true);
		}
	}

	createLocalId() {
		return this.store.getters.user.id + '_' + (new Date()).getTime();
	}

	async createCollections() {
		this.addCollection("categories", { indices: ['id'] });
		this.addCollection("products", { indices: ['id'] });
		this.addCollection("product_variants", { indices: ['id'] });
		this.addCollection("sale_options", { indices: ['id'] });
		this.addCollection("variants", { indices: ['id'] });
		this.addCollection("tables", { indices: ['id', 'busy', 'name'] });
		this.addCollection("consecutives", { indices: ['id'] });
		this.addCollection("orders", { indices: ['id', 'local_reference', 'order_status', 'date_order', 'consecutive_order_text', 'is_sync', 'bill_cash_register_turn_id', 'turn_local_reference'], unique: ['consecutive_order'] });
		this.addCollection("payment_methods", { indices: ['id', 'code', 'ordered'] });
		this.addCollection("price_list", { indices: ['id'] });
		this.addCollection("taxes", { indices: ['id'] });
		this.addCollection("cash_register", { indices: ['id'] });
		this.addCollection("cash_register_turn", { indices: ['id', 'local_reference', 'opened', 'is_sync', 'active'] });
		this.addCollection("employees", { indices: ['id', 'employee_type'] });
		this.addCollection("installation", { indices: ['id'] });
		this.addCollection("templates", { indices: ['id'] });
		this.addCollection("configuration", { indices: ['id'] });
		this.addCollection("print_config", { indices: ['id'] });
		//this.addCollection("configuration", { indices: ['id'] });
	}

	async install(data) {
		this.createCollections();
		const collections = await this.getCollections();
		for (const c of collections) {
			let collection = this.getCollection(c.name);
			if (collection) {
				try {
					if (data[c.name] && data[c.name].length > 0) {
						await collection.insert(data[c.name]);
						console.log('...')
					}
				} catch (e) {
					console.error(c.name, e)
				}
			}
		}
		console.log('end installation')
	}

	async getPrintConfig() {
		try {
			const collection = this.getCollection('print_config');
			const installObj = await collection.find({});
			if (!installObj || installObj.length == 0) {
				return null;
			}
			return installObj[0];
		} catch (e) {
			return null
		}
	}

	async getInstallation() {
		try {
			const collection = this.getCollection('installation');
			const installObj = await collection.find({});
			if (!installObj || installObj.length == 0) {
				return null;
			}

			if (!installObj[0].device_id) {
				installObj[0].device_id = (new Date()).getTime();
				await this.updateInstallation(installObj[0]);
			}
			return installObj[0];
		} catch (e) {
			return null
		}
	}

	async itsInstalled() {
		const installObj = await this.getInstallation();

		if (!installObj) {
			return false;
		}
		return true;
	}

	async getPriceLists() {
		const collection = this.getCollection('price_list');
		return await collection.find({});
	}

	async getConfigurations() {
		const collection = this.getCollection('configuration');
		return await collection.find({});
	}

	async getSaleOptions() {
		const collection = this.getCollection('sale_options');
		return await collection.find({});
	}

	async getVariants() {
		const collection = this.getCollection('variants');
		return await collection.find({});
	}

	async getProductVariants(variantId) {
		const collection = this.getCollection('product_variants');
		return await collection.findOne({ id: variantId });
	}

	async getTax(id) {
		const collection = this.getCollection('taxes');
		return await collection.findOne({ id: id });
	}

	async getSaleOption(id) {
		const collection = this.getCollection('sale_options');
		return await collection.findOne({ id: id });
	}

	async getProduct(id) {
		const collection = this.getCollection('products');
		return await collection.findOne({ id: id });
	}

	async getTemplate(id) {
		const collection = this.getCollection('templates');
		return await collection.findOne({ id: id });
	}

	async getTemplateByName(value) {
		const collection = this.getCollection('templates');
		return await collection.findOne({ name: value });
	}

	async getOpenTurn() {
		const collection = this.getCollection('cash_register_turn');
		let turn = await collection.findOne({ opened: true });

		if (turn) {
			// posiblemente cuando no se sincroniza o cuando se sincroniza se devuelve solo como string y no como object;
			if (turn.cash_register && !turn.cash_register.id) {
				turn.cash_register = { id: turn.cash_register }
			}
		}
		//let cash_register = await this.db.getCashRegister(turn.cash_register.id);
		return turn;
	}

	async getCashRegister(id) {
		const collection = this.getCollection('cash_register');
		return await collection.findOne({ id: id });
	}

	async getOrderByConsecutive(value) {
		const collection = this.getCollection('orders');
		return await collection.findOne({ consecutive_order_text: value });
	}

	async getConsecutiveById(id) {
		const collection = this.getCollection('consecutives');
		return await collection.findOne({ id: id });
	}

	async getConsecutives() {
		const collection = this.getCollection('consecutives');
		return await collection.find({});
	}

	async getEmployeesByType(type) {
		const collection = this.getCollection('employees');
		return await collection.find({ employee_type: type });
	}

	async getOrdersByType(type) {
		const collection = this.getCollection('orders');
		return await collection.find({ order_status: type });
	}

	// esta funcion es solo para filtrar ordenes que estan pendientes por pagar
	async getOpenOrders() {
		const collection = this.getCollection('orders');
		return await collection.find({ /*order_status: { $nin: ['closed', 'pending']},*/ is_open: true });
		//return await collection.find({ is_open: true });
	}

	async getPaymentMethods() {
		const collection = this.getCollection('payment_methods');
		return await collection.chain().find().simplesort('ordered').data();
	}

	async getTables() {
		/*const collection = this.getCollection('tables');
		return await collection.find();*/
		return true;
	}

	async getTableByName(name) {
		const collection = this.getCollection('tables');
		return await collection.findOne({ name: name });
	}

	async searchLocalOrders(params, limit, page, sortField) {
		if (!limit) {
			limit = 10;
		}
		if (!sortField) {
			sortField = 'date_order';
		}
		//console.log(params, limit, sortField)
		const collection = this.getCollection('orders');
		return await collection.chain().find().compoundsort([[sortField, true]]).offset((page - 1) * limit).limit(limit).data();
	}

	async searchLocalTurns(params, limit, page, sortField) {
		if (!limit) {
			limit = 10;
		}
		if (!sortField) {
			sortField = 'date_order';
		}
		//console.log(params, limit, sortField)
		const collection = this.getCollection('cash_register_turn');
		return await collection.chain().find().compoundsort([[sortField, true]]).offset((page - 1) * limit).limit(limit).data();
	}

	async getCollections() {
		return this.db.listCollections();
	}
	updateInfo() {

	}


	async updateConsecutiveCurrentNumber(objConsecutive) {
		objConsecutive['current_number'] = objConsecutive['current_number'] + 1;
		const collection = this.getCollection('consecutives');
		await this.findAndUpdateBy('id', objConsecutive, collection);
	}

	async updateTableBusy(table, value) {
		table['busy'] = value
		const collection = this.getCollection('tables');
		await this.findAndUpdateBy('id', table, collection);
	}



	/*load() {
		return new Promise((resolve, reject) => {
			console.log('interval.....')
			this.interval = setInterval(() => {
				if (this.loaded) {
					clearInterval(this.interval);
					this.interval = null;
					resolve(true);
				}
			}, 250);
		});
	}*/

	addCollection(name, config) {
		if (!this.getCollection(name)) {
			//console.log('[database] collection created: ', name)
			return this.db.addCollection(name, config);
		} else {
			//console.log('[database] collection already exists: ', name)
			return this.getCollection(name);
		}
	}

	getCollection(name) {
		return this.db.getCollection(name);
	}
	removeCollection(name) {
		return this.db.removeCollection(name);
	}
	deleteAll(name) {
		this.getCollection(name).findAndRemove({});
	}

	printAll(name) {
		console.log(this.getCollection(name).find({}));
	}

	async findOrderByLocalReference(ref) {
		const collection = this.getCollection('orders');
		let localOrder = await collection.findOne({ 'local_reference': ref });
		return localOrder;
	}

	async getOrdersByTurn(turn) {
		//console.log('search', turn);
		const collection = this.getCollection('orders');
		return await collection.find({ turn_local_reference: turn.local_reference, order_status: 'closed' });
	}

	async getOpenOrdersByTurn(turn) {
		const collection = this.getCollection('orders');
		return await collection.find({ turn_local_reference: turn.local_reference, order_status: 'pending' });
	}
	async getTurn(local) {
		const collection = this.getCollection('cash_register_turn');
		let turn = await collection.findOne({ local_reference: local });

		if (turn) {
			// posiblemente cuando no se sincroniza o cuando se sincroniza se devuelve solo como string y no como object;
			if (turn.cash_register && !turn.cash_register.id) {
				turn.cash_register = { id: turn.cash_register }
			}
		}
		//let cash_register = await this.db.getCashRegister(turn.cash_register.id);
		return turn;
	}
	getSubTreeCategory(catParent, categories) {
		//console.log(catParent.name, '_______')
		for (const i in categories) {
			let cat = JSON.parse(JSON.stringify(categories[i]))

			if (catParent.id == cat.inv_category_id) {
				//console.log(catParent.name, cat.name)
				if (!catParent.children) {
					catParent.children = []
				}
				//categories.splice(i, 1)
				//this.getTreeCategory(cat, categories);
				//console.log(cat)
				catParent.children.push(cat);

				//console.log('..........', cat.name, catParent.children.length)
			}
		}

	}

	async getTreeCategory(catParent) {


		const collection = this.getCollection('categories');

		const categories = await collection.find({ inv_category_id: catParent.id });

		for (const cat of categories) {
			cat.children = await this.getTreeCategory(cat);
		}
		return categories;
	}


	async getAllCategories() {
		const collection = this.getCollection('categories');
		const catParents = [];
		const categories = await collection.find({ inv_category_id: null });
		for (const c of categories) {
			catParents.push(c)
		}
		for (const catParent of catParents) {
			catParent.children = await this.getTreeCategory(catParent);
		}
		return catParents;
	}

	async getAllMedications(filters) {
		console.log(filters)
		const collection = this.getCollection('products');
		const filtersQuery = { info_medication : { '$exists': true }  };
		let results = null;
		if(filters){
			if(filters.provider_name){
				filtersQuery['info_medication.provider_name'] = { '$regex': new RegExp(filters.provider_name, 'i') }
			}
			if(filters.ean){
				filtersQuery['info_medication.ean'] = { '$regex': new RegExp(filters.ean, 'i') }
			}
			if(filters.name){
				filtersQuery['info_medication.name'] = { '$regex': new RegExp(filters.name, 'i') }
			}
			if(filters.key_word){
				//filtersQuery['$where'] = function(obj) {
					//console.log(obj)
					// Verificar si existe el campo 'info_medication'
					/*if (obj.info_medication) {
					  // Convertir el JSON de 'info_medication' a una cadena
					  let infoStr = JSON.stringify(obj.info_medication).toLowerCase();
					  
					  // Verificar si la palabra clave está en la cadena
					  return infoStr.includes(filters.key_word.toLowerCase());
					}
					return false;
				  }*/
				  results = await collection.chain().find(filtersQuery).where(function (obj) {
					let infoStr = JSON.stringify(obj.info_medication).toLowerCase();
					let keys = filters.key_word.toLowerCase().split(' ');
					
					return keys.every(key => infoStr.includes(key));
					}).data();
			}else{
				results = await collection.find(filtersQuery);
			}
		}
		 
		/*for (const c of categories) {
			catParents.push(c)
		}
		for (const catParent of catParents) {
			catParent.children = await this.getTreeCategory(catParent);
		}*/
		return results;
	}

	async getAllOrders() {
		/*
		const collection = this.getCollection('orders');
		return await collection.find();*/
		return true;
	}

	async getOrdersNotSyncro() {
		const collection = this.getCollection('orders');
		return await collection.find({ is_sync: false }); //order_status: 'closed'
	}

	async getTurnsNotSyncro() {
		const collection = this.getCollection('cash_register_turn');
		return await collection.find({ is_sync: false });
	}

	async findTurnByLocalReference(ref) {
		const collection = this.getCollection('cash_register_turn');
		let localOrder = await collection.findOne({ 'local_reference': ref });
		return localOrder;
	}

	async findAndUpdateBy(field, item, collection) {
		const filter = {};
		filter[field] = { '$eq': item[field] };

		const obj = await collection.findOne(filter);
		//console.log(obj)
		//console.log(filter)
		if (obj) {
			await collection.findAndUpdate(
				filter,
				(row) => {
					//console.log(row)
					for (const i in item) {
						row[i] = item[i];
					}
					return row;
				});
		} else {
			collection.insert(item);
		}
	}

	async updateInstallation(item) {
		const collection = this.getCollection('installation');
		if (collection) {

			await this.findAndUpdateBy('id', item, collection)

		}
	}

	async updateOrders(items) {
		const collection = this.getCollection('orders');
		if (collection) {
			for (const item of items) {
				/*if(collection == 'templates'){
					console.log(collection)
				}*/
				await this.findAndUpdateBy('local_reference', item, collection)
			}
		}
	}

	async updateItems(nameCollection, items) {
		const collection = this.getCollection(nameCollection);
		//console.log(collection, items)
		if (collection) {
			for (const item of items) {
				/*if(collection == 'templates'){
					console.log(collection)
				}*/
				await this.findAndUpdateBy('id', item, collection)
			}
		}
	}
	searchVariant(prdId, options) {
		const collection = this.getCollection('product_variants');
		const items = collection.chain().find({ 'inv_product_id': { '$eq': prdId } }).
			where(function (obj) {
				const eq = equalsIgnoreOrder(options, obj.inv_variant_option_ids);
				return eq;
			}).simplesort('id').limit(1).data();

		if (items && items.length > 0) {
			const v = items[0];
			return { id: v.id, name: v.name, referecnce: v.reference, bar_code: v.bar_code, options: v.inv_variant_option_ids};
		}
		return null;
	}

	containsOptions(prdId, options) {
		const collection = this.getCollection('product_variants');
		const items = collection.chain().find({ 'inv_product_id': { '$eq': prdId } }).
			where(function (obj) {
				const eq = containsAll(options, obj.inv_variant_option_ids);

				return eq;
			}).simplesort('id').limit(1).data();

		if (items && items.length > 0) {
			const v = items[0];
			return { id: v.id, name: v.name, referecnce: v.reference, bar_code: v.bar_code, options: v.inv_variant_option_ids};
		}
		return null;
	}

	searchProductVariantByCodeBarOrReference(params, limit, sortField) {

		if (!sortField) {
			sortField = 'name';
		}
		const collection = this.getCollection('product_variants');

		return collection.chain().find(params).simplesort(sortField).limit(1).data();
	}

	searchProductByCodeBarOrReference(params, limit, sortField) {

		if (!sortField) {
			sortField = 'name';
		}
		const collection = this.getCollection('products');

		return collection.chain().find(params).simplesort(sortField).limit(1).data();
	}

	searchProducts(params, limit, sortField, offset) {
		if (!limit) {
			limit = 20;
		}
		if (!sortField) {
			sortField = 'name';
		}
		//offset = 10
		const collection = this.getCollection('products');
		//console.log(limit, offset)
		if (offset) {
			return collection.chain().find(params).simplesort(sortField).offset(offset).limit(limit).data();
		} else {
			return collection.chain().find(params).simplesort(sortField).limit(limit).data();
		}

	}



	getTotalLocalInvoices() {
		return new Promise(async (resolve, reject) => {
			const collection = this.getCollection('orders');
			const total = await collection.count();
			resolve(total);
		});
	}

	getTotalLocalTurns() {
		return new Promise(async (resolve, reject) => {
			const collection = this.getCollection('cash_register_turn');
			const total = await collection.count();
			resolve(total);
		});

	}

	saveOrder(order, http, showMsgServer) {
		return new Promise(async (resolve, reject) => {
			if (!order.local_reference) {
				order.local_reference = this.createLocalId()
			}
			order.is_sync = false;

			if (!order.date_order) {
				//order.date_order = new Date();
			}
			/*
			let localOrder = await this.findOrderByLocalReference(order.local_reference);
			//const installation = this.store.getters.installation;
			const collection = this.getCollection('orders');
			if (localOrder) {
				this.findAndUpdateBy('local_reference', order, collection)
			} else {
				const or = collection.insert(order);
			}
			*/
			if (http && this.store.getters.online) {
				let paramsRequest = '';
				if (order.id) {
					paramsRequest = '/' + order.id;
				}
				if (!order.order_status) {
					order.order_status = 'created';
				}
				delete order.is_sync;
				http.post('api/v1/order' + paramsRequest, order, false, showMsgServer).then(async (response) => {
					if (response.success) {
						// la respuesta del servidor debe traer la fecha de syncronización
						delete response.item['_actions'];

						response.item['is_sync'] = true;
						/*
						order = response.item;
						order.id = response.item.id;
						order.consecutive_order_text = response.item.consecutive_order_text;
						*/
						// this.findAndUpdateBy('local_reference', response.item, collection);
						resolve(response)
					}
				}).catch(err => {
					console.log(err)
					reject(err)

				});
			}
		})

	}

	saveOrderRemote(order, http, showMsgServer) {
		return new Promise(async (resolve, reject) => {
			if (!order.local_reference) {
				order.local_reference = this.createLocalId()
			}
			//order.is_sync = false;

			if (!order.date_order) {
				//order.date_order = new Date();
			}
			//let localOrder = await this.findOrderByLocalReference(order.local_reference);
			//const installation = this.store.getters.installation;
			/*const collection = this.getCollection('orders');
			if (localOrder) {
				this.findAndUpdateBy('local_reference', order, collection)
			} else {
				const or = collection.insert(order);
			}*/
			if (http && this.store.getters.online) {
				let paramsRequest = '';
				if (order.id) {
					paramsRequest = '/' + order.id;
				}
				if (!order.order_status) {
					order.order_status = 'created';
				}
				delete order.is_sync;
				http.post('api/v1/order' + paramsRequest, order, false, showMsgServer).then(async (response) => {
					if (response.success) {
						// la respuesta del servidor debe traer la fecha de syncronización
						delete response.item['_actions'];

						//response.item['is_sync'] = true;
						//order.id = response.item.id;
						//this.findAndUpdateBy('local_reference', response.item, collection);
					}
					resolve(response)					
				}).catch(err => {
					console.log(err)
					reject(err)

				});
			} else {
				// alert('No hay conexión a internet');
				reject('No hay conexión a internet');
			}
		})

	}

	async cleanLocalOrders(date) {
		const collection = this.getCollection('orders');
		const items = await collection.chain().find({ is_sync: true, order_status: 'closed' }).where(function (obj) {
			return obj.date_order < date;
		}).remove();
	}

	async cleanLocalTurns(date) {
		const collection = this.getCollection('cash_register_turn');
		const items = await collection.chain().find({ is_sync: true, opened: false }).where(function (obj) {
			return obj.opening_date < date;
		}).remove();
	}


	async saveTurn(turn, http) {
		return new Promise(async (resolve, reject) => {
			if (http && this.store.getters.online) {
				//console.log('local_turn', turn);
				if (!turn.local_reference) {
					turn.local_reference = this.createLocalId()
				}
				if (!turn['user_created_id']) {
					turn['user_created_id'] = this.store.getters.user.id;
					turn['user_updated_id'] = this.store.getters.user.id;
				}

				turn.is_sync = false;
				// let localTurn = await this.findTurnByLocalReference(turn.local_reference);
				// const collection = this.getCollection('cash_register_turn');
				// if (localTurn) {
				// 	this.findAndUpdateBy('local_reference', turn, collection)
				// } else {
				// 	turn = collection.insert(turn);
				// }

				let paramsRequest = '';
				if (turn.id) {
					paramsRequest = '/' + turn.id;
				}

				if (turn.company && turn.company.id) {
					turn.company = turn.company.id
				}
				if (turn.subsidiary && turn.subsidiary.id) {
					turn.subsidiary = turn.subsidiary.id
				}
				if (turn.cash_register && turn.cash_register.id) {
					turn.cash_register = turn.cash_register.id
				}
				let copyTurn = JSON.parse(JSON.stringify(turn));
				//delete copyTurn.opening_date;
				http.post('api/v1/cash-turn' + paramsRequest, turn, false, false).then(async (response) => {
					if (response.success) {
						console.log('server_turn', response.item);
						// la respuesta del servidor debe traer la fecha de syncronización
						delete response.item['_actions'];

						response.item['is_sync'] = true;
						this.store.commit("setTurn", response.item);
						// this.findAndUpdateBy('local_reference', response.item, collection);
						resolve(true)

					}else{
						reject(response)
					}
				}).catch(err => {
					console.log(err)
					reject(err);
				});
			} else {
				alert('No hay conexión a internet');
				reject(err);

			}
		});
	}

	serialize() {
		return this.db.serialize();
	}

	loadDB(data) {

		this.createCollections()
		for (let collection of data.collections) {

			try {
				if (collection.data.length > 0) {
					let c = this.getCollection(collection.name);
					let rows = collection.data.map((item) => {
						delete item.meta
						delete item.$loki
						return item
					})
					if (c) {
						c.insert(rows);
					}
				}
			} catch (e) { console.log(e) }
		}


	}
}

export default LokiAdapter;