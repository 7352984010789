import { render, staticRenderFns } from "./ReceiveCallModal.vue?vue&type=template&id=0f537ce4&scoped=true"
import script from "./ReceiveCallModal.vue?vue&type=script&lang=js"
export * from "./ReceiveCallModal.vue?vue&type=script&lang=js"
import style0 from "./ReceiveCallModal.vue?vue&type=style&index=0&id=0f537ce4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f537ce4",
  null
  
)

export default component.exports