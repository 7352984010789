import Vue from 'vue'
import Vuex from 'vuex'
//import user from './store/user'
//import auth from './store/auth'
import shared from './shared'
import db from './../plugins/localStorage/store'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  
  },
  mutations: {

  },
  getters: {
  
  },
  actions: {

  },
  modules: {
    //user: user,
    //auth: auth,
    shared: shared,
    db: db
  },
})

