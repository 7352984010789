<template>
  <div class="d-flex" v-if="isTouch || activeKeyboard">
    <v-menu v-model="openKeyBoard" :close-on-content-click="false" :nudge-width="200" offset-y :nudge-top="nudgetTop" >
      <template v-slot:activator="{ on, attrs }">
        <v-row class="ma-0 pa-0" v-bind="attrs" v-on="on">
          <validation-provider
            v-slot="{ errors }"
            :name="$t(label)"
            :rules="rules"
            style="width: 100%"
          >
            <v-text-field
              :value="value"
              v-bind="{ ...config, ...$attrs }"
              :error-messages="errors"
              :label="$t(label) + labelRequired()"
              :type="getType()"
              v-on="$listeners"
              @input="onInputChange"
              append-icon="mdi-keyboard"
              @click:append="openKeyboard()"
              :clearable="false"
              :readonly="readOnly"       
            />
          </validation-provider>
          <ng-help v-if="help" :text="help" />
        </v-row>
      </template>
      <SimpleKeyboard :keyboardClass="'keyboard_text_'+replaceSpaces(label)+'_'+datetime" @onChange="onChange" @onKeyPress="onKeyPress" :input="valueKeyboard"/>
    </v-menu>   
  </div>
  <div class="d-flex" v-else>
    <validation-provider
      v-slot="{ errors }"
      :name="$t(label)"
      :rules="rules"
      style="width: 100%"
    >
      <v-text-field
        :value="value"
        v-bind="{ ...config, ...$attrs }"
        :error-messages="errors"
        :label="$t(label) + labelRequired()"
        :type="getType()"
        v-on="$listeners"
        @input="inputEvt"
      />
    </validation-provider> 
  </div>
</template>
<script>
import AbstractField from "./AbstractField";
import SimpleKeyboard from "./SimpleKeyboard";

export default {
  name: "NgTextKeyboard",
  components: {
    SimpleKeyboard
  },
  props: {
    nudgetTop: {
      default: 30,
      type: Number
    },
    label: {
      default: null,
      type: String
    },
    readOnly: {
      default: true,
      type: Boolean
    }, 
    activeKeyboard:{
      default: false,
      type: Boolean
    }
  },
  data:() => ({
    openKeyBoard :false,
    valueKeyboard: '',
    isTouch: false,
    datetime: '',
  }),
  mixins: [AbstractField],
  mounted() {
    this.datetime = new Date().getTime();
    this.isTouch = this.$store.getters.getPosValue('pos_activate_touchscreen');
  },
  methods: {
    getType() {
      /*if (this.options.type) {
        return this.options.type;
      }*/
      return "text";
    },
    inputEvt(evt) {
      if (this.value && typeof this.value == "string") {
        if (this.mask) {
          const mask = this.mask;
          const patt = new RegExp(mask);

          this.$nextTick().then(() => {
            if (this.value && patt.test(this.value)) {
              const val = this.value.replace(patt, "");
              this.$emit("input", val);
            }
          });
        }
      }else{
        this.$emit("input", evt);
      }
    },
    replaceSpaces(string){
      if(string){
        return string.replace( /\s/g, '_').toLowerCase();
      }
      return 'txt';      
    },
    openKeyboard(){
      this.openKeyBoard = true;
    },
    onChange(input) {
      //this.value = input;
      this.$emit("input", input);
    },
    onKeyPress(button) {


      if(button == '{enter}'){
        this.$emit("input", this.value);
      }
    },
    onInputChange(input) {
      this.$emit("input", input);
    }
  },
};
</script>
