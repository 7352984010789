//import { required, digits, email, max, min, regex } from 'vee-validate/dist/rules'
import { extend /*, ValidationObserver, ValidationProvider, setInteractionMode*/ } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules';



Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});
/*
extend('digits', {
  ...digits,
})

extend('max', {
  ...max,
})
extend('max', {
    ...max,
})

extend('regex', {
  ...regex,
})
*/