<template>
  <div class="">
    <div>
      <span>{{ $t(label) + labelRequired() }}</span>
      <div class="editor-code">
        <table style="width: 100%" v-if="!fullscreen">
          <tbody>
            <tr>
              <td>
                <div
                  ref="editor"
                  style="min-width: 600px; width: 100%"
                  :style="{ height: height + 'px' }"
                ></div>
              </td>
            </tr>
            <tr v-if="preview">
              <td class="pa-2">
                <v-btn
                  v-if="showSaveBtn"
                  color=""
                  @click="$emit('save')"
                  small
                  class="mr-2"
                  :loading="loading"
                  >Guardar</v-btn
                >
                <v-btn
                  color=""
                  @click="showFullscreenEditor()"
                  small
                  class="mr-2"
                  >Pantalla Completa</v-btn
                >
                <v-btn small color="" @click="showPreview = !showPreview"
                  >Preview</v-btn
                >

                <div class="pa-2" v-if="showPreview">
                  <v-card>
                    <component
                      :is="getComponent()"
                      :key="keyRender"
                    ></component>
                  </v-card>
                </div>
              </td>
            </tr>
            <tr v-if="preview">
              <td v-if="messages">{{ messages }}</td>
            </tr>
          </tbody>
        </table>
        <div v-else>
          <v-dialog v-model="fullscreen" fullscreen>
            <div style="width: 100%; height: 100vh; background-color: white">
              <table style="width: 100%" v-if="fullscreen">
                <tbody>
                  <tr>
                    <td style="width: 50%">
                      <div
                        ref="editorFullscreen"
                        style="width: 100%; height: 100vh"
                      ></div>
                    </td>

                    <td class="pa-2">
                      <div class="text-right">
                        <v-btn small class="mb-2" @click="closeFullscreen()"
                          >Guardar
                        </v-btn>

                        <v-btn small class="mb-2" @click="print()"
                          >Imprimir
                        </v-btn>
                      </div>
                      <v-card
                        style="
                          width: 100%;
                          height: calc(100vh - 80px);
                          background-color: white;
                        "
                      >
                        <div ref="printRef">
                          <component
                            :is="getComponent()"
                            :key="keyRender"
                          ></component>
                        </div>
                      </v-card>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </v-dialog>
        </div>
      </div>
    </div>
    <!--ng-help v-if="options.help" :text="options.help" /-->
  </div>
</template>
<script>
import AbstractField from "./AbstractField";
import Vue from "vue";

export default {
  name: "NgEditorCode",
  mixins: [AbstractField],
  data() {
    return {
      //_options: {},
      //_content: '',
      fullscreen: false,
      showPreview: false,
      editor: null,
      editorFullscreen: null,
      keyRender: 1,
      tmplBase: "<div>Hello</div>",
      loaded: false,
      css: "p{background-color: gray}",
    };
  },
  props: {
    value: String,
    messages: String,
    test: String,
    preview: {
      type: Boolean,
      default: true,
    },
    showSaveBtn: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    height: {
      type: Number,
      default: 200,
    },
  },
  watch: {
    // Watch content change
    value(newVal) {
      if (!this.loaded && this.$el && this.editor) {
        this.editor.setValue(this.value);
        this.loaded = true;
      }
    },
  },
  mounted() {
    this.initializeInput();
    /*var css = document.createElement('style');
        css.type='text/css';
        css.setAttributeNode( document.createAttribute('scopped') );
        css.appendChild(    document.createTextNode( this.css )     );
        this.$el.appendChild(   css     );*/
  },
  beforeDestroy() {
    //this.quill = null;
    //delete this.quill;
  },
  methods: {
    print() {
      try {
        var printContents = this.$refs.printRef.innerHTML;
        let w = window.open();
        w.document.write(printContents);
        w.print();
        w.close();
      } catch (e) {
        return "";
      }
    },
    closeFullscreen() {
      this.editorFullscreen = null;
      this.fullscreen = false;
      this.editor = null;
      setTimeout(() => {
        this.initializeInput();
      }, 100);
    },
    showFullscreenEditor() {
      if (this.$el && !this.editorFullscreen) {
        this.fullscreen = true;
        this.editor = null;
        setTimeout(() => {
          this.editorFullscreen = ace.edit(this.$refs.editorFullscreen);
          this.editorFullscreen.setTheme("ace/theme/monokai");
          this.editorFullscreen.session.setMode("ace/mode/html");
          this.editorFullscreen.getSession().on("change", () => {
            //this.value = this.editor.getValue();
            this.$emit("input", this.editorFullscreen.getValue());
            this.keyRender++;
          });

          if (this.value) {
            this.editorFullscreen.setValue(this.value);
            this.keyRender++;
          }
        }, 250);
      }
    },
    initializeInput() {
      if (this.$el && !this.editor) {
        this.editor = ace.edit(this.$refs.editor);
        this.editor.setTheme("ace/theme/monokai");
        this.editor.session.setMode("ace/mode/html");
        this.editor.getSession().on("change", () => {
          //this.value = this.editor.getValue();
          this.$emit("input", this.editor.getValue());
          this.keyRender++;
        });

        if (this.value) {
          this.editor.setValue(this.value);
          this.keyRender++;
        }
      }
    },
    getComponent() {
      let tmpl = this.tmplBase;
      /*if (this.editor) {
        tmpl = this.editor.getValue();

        if (!tmpl) {
          tmpl = this.tmplBase;
        }
      }*/
      tmpl = this.value;
      let info = {};

      if (this.test) {
        try {
          info = JSON.parse(this.test);
        } catch (e) {
          info = {};
        }
      }

      tmpl = "<div>" + tmpl + "</div>";
      return {
        data() {
          return {
            info: info,
          };
        },
        template: tmpl,
        style: "li{ background-color: blue}",
      };
    },
  },
};
</script>
<style>
</style>