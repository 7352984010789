<template>
  <div class="d-flex">
    <validation-provider
      v-slot="{ errors }"
      :name="label"
      :rules="rules"
      style="width: 100%"
    >
      <v-autocomplete
        :value="value"
        :loading="loadingSearch"
        v-bind="{ ...config, ...$attrs }"
        chips
        small-chips
        deletable-chips
        multiple
        :filter="filterFnc"
        :items="itemsServer"
        :label="$t(label) + labelRequired()"
        item-text="name"
        :open-on-clear="true"
        :error-messages="errors"
        :search-input.sync="search"
        :no-filter="true"
        :dense="false"
        v-on="$listeners"
        @input="inputEvt"
        return-object
      >
      </v-autocomplete>
    </validation-provider>
    <ng-help v-if="help" :text="help" />
  </div>
</template>
<script>
import NgAuto from "./NgAuto";
export default {
  name: "NgChips",
  mixins: [NgAuto],
  created() {
    if (this.value && this.value.length > 0 && this.itemsServer.length == 0) {
      this.itemsServer = JSON.parse(JSON.stringify(this.value));
    }
  },
  methods: {
    filterFnc(item, queryText, itemText) {
      const parts = queryText.split(" ");
      for (const part of parts) {
        if (itemText.toLowerCase().indexOf(part.toLowerCase()) < 0) {
          return false;
        }
      }
      return true;
    },
    querySelectionsSearch(v) {
      this.loadingSearch = true;
      const params = {};
      if (this.dependsOf) {
        for (const index in this.dependsOf) {
          params[index] = this.dependsOf[index];
        }
      }
      if (v) {
        params.name = v;
      }
      this.$http
        .get(this.getUrlQuery(), params)
        .then((data) => {
          if(this.value && this.value.length > 0){
            this.itemsServer = [...this.value, ...data.items];
          }else{
            this.itemsServer = data.items;
          }          
        })
        .catch(() => {
          this.loadingSearch = false;
        })
        .finally(() => {
          this.loadingSearch = false;
        });
    }
  },
};
</script>
