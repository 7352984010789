import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
/*
const ifAuthenticated = (to, from, next) => {
    if (localStorage.getItem('auth')) {
      next()
      return
    }
    next('/')
  }*/

export default new Router({
  // mode: 'hash',
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'login',
      component: () => import('@/components/modules/auth/Login')
    },
    {
      path: '/login',
      name: 'Login-Route',
      component: () => import('@/components/modules/auth/Login')
    },
    /*{
      path: '/store/',
      name: 'Login',
      component: () => import('@/components/modules/auth/Login')
    },*/
    {
      path: '/auth/recover',
      name: 'Recover',
      component: () => import('@/components/modules/auth/Recover')
    },
    /*{
      path: '/auth',
      component: () => import('@/modules/auth/Index'),
      children: [
        {
          name: 'Login',
          path: 'login',
          component: () => import('@/modules/auth/components/Login'),
        }
      ]
    },*/
    /*{
      path: '/reserva',
      component: () => import('@/modules/reservas/Index'),
      children: [
        {
          path: '',
          component: () => import('@/modules/reservas/components/Booking'),
        },
      ]
    },*/
    {
      path: '/support',
      component: () => import('@/components/modules/dbview/Index'),
    },
    {
      path: '/ventas',
      name: 'Ventas',
      // beforeEnter: ifAuthenticated,
      component: () => import('@/components/modules/waiters/IndexWaiters'),
      children: [
        {
          path: '',
          name: 'Pos Home',
          component: () => import('@/components/modules/waiters/HomeWaiters'),
        },
      ]
    }, {
      path: '/screen',
      name: 'Screen',
      // beforeEnter: ifAuthenticated,
      component: () => import('@/components/modules/screen/Index'),
      children: [
        {
          path: '',
          name: 'Screen Home',
          component: () => import('@/components/modules/screen/ScreenHome'),
        },
      ]
    },
    {
      path: '/pos',
      name: 'Pos',
      // beforeEnter: ifAuthenticated,
      component: () => import('@/components/modules/pos/Index'),
      children: [
        {
          path: '',
          name: 'Pos Home',
          component: () => import('@/components/modules/pos/components/Home'),
        },
        {
          path: 'configuration',
          component: () => import('@/components/modules/pos/components/configuration/CashRegisterUpdate'),
        },
        {
          path: 'tools',
          component: () => import('@/components/modules/pos/components/configuration/Tools'),
        },
        {
          path: 'close-turn',
          component: () => import('@/components/modules/pos/components/turn/CloseTurn'),
        },
        {
          path: 'turns',
          component: () => import('@/components/modules/pos/components/turn/CashRegisterTurnList'),
        },
        /*{
          path: 'turns-old',
          component: () => import('@/components/modules/pos/components/turn/LocalTurns'),
        },*/
        {
          path: 'local-orders',
          component: () => import('@/components/modules/pos/components/order/LocalOrders'),
        },
        {
          path: 'server-orders',
          component: () => import('@/components/modules/pos/components/order/ServerOrders'),
        },
        {
          path: 'orders-receive',
          component: () => import('@/components/modules/pos/components/order/OrdersReceive'),
        },
        {
          path: 'expense-draft',
          component: () => import('@/components/modules/pos/components/expense/ExpenseListDraft.vue'),
          meta: { title: 'Compras y Gastos - Egresos - Crear (En Borrador) - Listado' }

        },
        {
          path: 'expense-finalized',
          component: () => import('@/components/modules/pos/components/expense/ExpenseListFinalized.vue'),
          meta: { title: 'Compras y Gastos - Egresos - Aprobados' }

        },
        {
          path: 'expense-annulled',
          component: () => import('@/components/modules/pos/components/expense/ExpenseListAnnulled.vue'),
          meta: { title: 'Compras y Gastos - Egresos - Anulados' }

        },
        {
          path: 'expense/create',
          component: () => import('@/components/modules/pos/components/expense/ExpenseUpdate.vue'),
          meta: { title: 'Compras y Gastos - Egresos - Crear (En Borrador) - Crear' }

        },
        {
          path: 'expense/:id',
          component: () => import('@/components/modules/pos/components/expense/ExpenseUpdate.vue'),
          meta: { title: 'Compras y Gastos - Egresos - Crear (En Borrador) - Actualizar' }

        },

        {
          path: 'de-credit-note-draft',
          component: () => import('@/components/modules/pos/components/creditNote/CreditNoteListDraft.vue'),
          meta: { title: 'Notas Crédito (DE) - Crear (En Borrrador) - Listado' }
        },
        {
          path: 'de-credit-note-finalized',
          component: () => import('@/components/modules/pos/components/creditNote/CreditNoteListFinalized.vue'),
          meta: { title: 'Notas Crédito (DE) - Aprobados' }

        },
        {
          path: 'de-credit-note-annulled',
          component: () => import('@/components/modules/pos/components/creditNote/CreditNoteListAnnulled.vue'),
          meta: { title: 'Notas Crédito (DE) - Anulados' }

        },
        {
          path: 'de-credit-note/create',
          component: () => import('@/components/modules/pos/components/creditNote/CreditNoteUpdate.vue'),
          meta: { title: 'Notas Crédito (DE) - Crear (En Borrrador) - Crear' }

        },
        {
          path: 'de-credit-note/:id',
          component: () => import('@/components/modules/pos/components/creditNote/CreditNoteUpdate.vue'),
          meta: { title: 'Notas Crédito (DE) - Crear (En Borrrador) - Actualizar' }

        },



      ]
    },
  ],
})
