<template>
  <div class="d-flex">
    <validation-provider
      v-slot="{ errors }"
      :name="label"
      :rules="rules"
      style="width: 100%"
    >
      <v-text-field
        :value="value"
        v-bind="{ ...config, ...$attrs }"
        :label="$t(label) + labelRequired()"
        v-on="$listeners"
        :error-messages="errors"
        @input="inputEvt"
        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
        :type="show1 ? 'text' : 'password'"
        @click:append="show1 = !show1"
      />
    </validation-provider>
    <ng-help v-if="help" :text="help" />
  </div>
</template>
<script>
import AbstractField from "./AbstractField";
export default {
  name: "NgPasswd",
  mixins: [AbstractField],
  data() {
    return {
      show1: false,
    };
  },
  methods: {
    getType() {
      if (this.type) {
        return this.type;
      }

      return "text";
    },
  },
};
</script>
