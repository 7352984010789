import { io } from 'socket.io-client';
function connectToSocket() {
    return io('wss://app.nubempresa.com', {
        //host: 'ws://5.161.63.54',
        // port: 8080,
        path: '/socket.io',
        transports: ['websocket'],
    });
}
const socket = connectToSocket();
socket.on("connect", () => {
    console.log("socket conected");
});
export { socket };