<template>
  <v-row class="page-breadcrumb pa-6 pb-0 mb-0">
    <v-col cols="12" sm="12">
      <v-card class="px-2">
        <v-card-text class="pa-3">
          <v-row justify="space-between">
            <v-col cols="12" lg="6">
              <div class="v-toolbar__title mb-2">
                <span class="text--primary">{{ title }}</span>
              </div>
              <v-toolbar-title class="toobar-extension transparent pb-3" slot="extension" v-if="breadcrumbs">
                <v-breadcrumbs :items="breadcrumbs" class="pa-0">
                  <template v-slot:divider v-if="breadcrumbs">
                    <v-icon>mdi-chevron-right</v-icon>
                  </template>
                </v-breadcrumbs>
              </v-toolbar-title>
            </v-col>
            <v-col cols="12" lg="6" class="justify-end align-center d-flex">
              <slot name="actions"></slot>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "BaseBreadcrumb",
  props: {
    title: String,
    breadcrumbs: Array,
    icon: String,
  },
  data: () => ({}),
};
</script>
<style lang="scss">
.page-breadcrumb {
  margin: -24px -25px 27px -25px;
}
</style>