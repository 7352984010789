<template>
  <div>
    <div class="d-flex">
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <validation-provider
            v-slot="{ errors }"
            :name="label"
            :rules="rules"
            style="width: 100%"
          >
            <v-text-field
              :value="value"
              v-bind="{ ...config, ...$attrs }"
              prepend-inner-icon="mdi-calendar"
              :label="$t(label) + labelRequired()"
              :error-messages="errors"
              @input="inputEvt"
              readonly
              v-on="on"
            ></v-text-field>
          </validation-provider>
        </template>
        <v-date-picker
          :value="value"
          ref="picker"
          type="date"
          min="1920-01-01"
          :max="max"
          @change="save"
          @input="menu = false"
          locale="es"
          no-title
        >
        </v-date-picker>
      </v-menu>

      <ng-help v-if="help" :text="help" />
    </div>
  </div>
</template>
<script>
import AbstractField from "./AbstractField";
import * as moment from "moment-timezone";

export default {
  name: "NgDate",
  mixins: [AbstractField],
  data: () => ({
    date: "", //new Date().toISOString().substr(0, 10),
    menu: false,
    modal: false,
    max: null,
  }),
  props: ['birthday', 'current', 'activePicker'],
  created() {
    //this.$refs.picker.activePicker = 'YEAR';
    if (this.birthday) {
      this.max = new Date().toISOString().substr(0, 10);
    }
    if (this.current) {
      this.value = new Date().toISOString().substr(0, 10);
    }
  },
  methods: {
   /* inputEvt(evt){
      this.$emit("input", (evt));
    },*/
    save(evt) {
      this.$emit("input", this.dateFormat(evt));
    },
    dateFormat(str) {
      if (str && str != null) {
        // return moment.tz(str, "America/Bogota").utc(); // .format("MMM D/ YYYY");
        // return moment(str).utc();
        return moment.tz(str, "America/Bogota").utc().format("YYYY-MM-DD");
      }
      return "";
    },
  },
  /*computed: {
    value: {
      cache: false,
      get: function () {
        if (this.options) {
          return this.dateFormat(this.value);
        }
        return null;
      },
      set: function (nv) {
        this.value = this.dateFormat(nv);
      },
    },
  },*/
  watch: {
    menu(val) {
      if (val && this.activePicker) {
        setTimeout(() => {
          this.$refs.picker.activePicker = this.activePicker; //'YEAR'
        });
      }
    },
  },
};
</script>
