<template>
  <div>
    <div class="text-right">
        <span class="mr-2">{{$t('Total Productos')}}: {{actives.length}}</span>
        <v-btn @click="save" small color="primary">
            {{$t('Guardar')}}
        </v-btn>

        <v-btn @click="preview" small color="primary">
            {{$t('Ver')}}
        </v-btn>
    </div>
  <div v-if="loading">
    <v-progress-linear
            indeterminate
            color="primary"
            class="mt-4"
          ></v-progress-linear>
  </div>
    <v-sheet outlined class="mt-2" style="max-height: 300px; overflow-y: auto" v-if="items">
      <v-treeview
        style="width: 100%"
        selection-type="independent"
        :active.sync="actives"
        :open-all="true"
        multiple-active
        activatable
        dense
        return-object
        :items="items"
      >
        <template v-slot:prepend="{ active, item }">
          <span v-if="!item.disabled">
            <v-icon color="primary" v-if="active">mdi-checkbox-marked</v-icon>
            <v-icon color="primary" v-if="!active"
              >mdi-checkbox-blank-outline</v-icon
            >
          </span>
        </template>
      </v-treeview>
    </v-sheet>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      actives: [],
      items: null,
    };
  },
  props: ["item"],
  watch: {
    item: {
      deep: true,
      handler() {
        this.loadItems();
      },
    },
  },
  methods: {
    preview(){
      //window.open(this.$http.getBaseUrl() + "/api/v1/catalog/" + this.item.id + "/preview")

      this.$http.getRaw("api/v1/catalog/" + this.item.id + "/preview").then((response) => {
          var win = window.open("", "Catalogo", "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=780,height=200,top="+(screen.height-400)+",left="+(screen.width-840));
          //var win = window.open("", '_blank');
          win.document.body.innerHTML = response;
          setTimeout(() => {
            win.print();
          }, 1000);
      });
    },
    save() {
      this.loading = true;
      this.$http
        .post("api/v1/catalog/" + this.item.id + "/config-products", { products: this.actives }, true, true)
        .then((data) => {
        
        })
        .catch((err) => {
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });   
    },
    reload() {
      this.$nextTick().then(() => {
        this.loadItems();
      });
    },
    loadItems() {
      if (this.item) {
        this.queryOptions();
      }
    },
    queryOptions(v) {
      const params = {};

      this.loading = true;
      this.$http
        .get( "api/v1/catalog/" + this.item.id + "/config-products")
        .then((data) => {
          this.$nextTick().then(() => {
            this.loading = false;
            this.items = data.items;
            this.actives = data.products;
          });
        })
        .catch((err) => {

          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
