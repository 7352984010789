<template>
  
    <div class="d-flex">
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            :value="valuef.from"
            v-bind="{ ...config, ...$attrs }"
            prepend-inner-icon="mdi-calendar"
            :label="$t(label) + labelRequired()"
            placeholder="Desde"
            readonly
            @input="inputEvt"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          :value="valuef.from"
          ref="picker"
          type="date"
          min="1920-01-01"
          :max="max"
          @change="save"
          @input="menu = false"
          no-title
        >
        </v-date-picker>
      </v-menu>

      <v-menu
        ref="menuTo"
        v-model="menuTo"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            :value="valuef.to"
            v-bind="{ ...config, ...$attrs }"
            prepend-inner-icon="mdi-calendar"
            label=""
            placeholder="Hasta"
            readonly
            @input="inputEvt"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          :value="valuef.to"
          ref="picker"
          type="date"
          min="1920-01-01"
          :max="max"
          @change="saveTo"
          @input="menuTo = false"
          no-title
        >
        </v-date-picker>
      </v-menu>
    </div>
</template>
<script>
import AbstractField from "./AbstractField";
export default {
  name: "NgDateRange",
  mixins: [AbstractField],
  data: () => ({
    menu: false,
    menuTo: false,
    max: null,
    valuef: { from: null, to: null },
  }),
  mounted() {
    /*if (
      this.options &&
      this.form[this.options.name] &&
      (this.form[this.options.name].from || this.form[this.options.name].to)
    ) {
      this.valuef = this.form[this.options.name];
    }*/
  },
  methods: {
    save(date) {
      const old = Object.assign({}, this.valuef);
      this.valuef.from = date;
      //this.value = this.valuef;
      this.$emit("input", this.valuef);
      /*this.form.emmit("change", {
        control: this.options.name,
        old_value: old,
        new_value: this.valuef,
        value: this.form.value,
      });*/
    },
    saveTo(date) {
      const old = Object.assign({}, this.valuef);
      this.valuef.to = date;
     // this.value = this.valuef;
      this.$emit("input", this.valuef);
      /*this.form.emmit("change", {
        control: this.options.name,
        old_value: old,
        new_value: this.valuef,
        value: this.form.value,
      });*/
    },
  },
};
</script>
