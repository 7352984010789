<template>
  <div :key="key">
    <label v-if="filters && filters.length > 0" class="mr-2">{{$t('Filtros:')}}</label>
    <v-chip
      v-for="item in filters"
      class="mr-2"
      close
      @click:close="cleanFilter(item.key)"
      :key="item.key"
      
    >
     {{$t(item.name)}}: {{item.value}}
    </v-chip>

</div>
</template>

<script>

  export default {
    name: 'ng-filters-chips',
    data: () => ({
      key: 1,
      //filters: []
    }),
    props: ['form', 'labels'], // 'pageConfg'
    computed:{
      filters() {
        return this.showFilters();
      }
    },
    watch: {
      form:{
        deep: true,
        handler(){
          this.showFilters();
        }
      }
    },
    mounted(){
      /*this.form.on('change', (val) => {
        this.showFilters();
      })*/
    },
    
   

    methods: {
      isDate(field){
        return typeof field.from != 'undefined' || typeof field.to != 'undefined'
      },
      isArray(field){
        return Array.isArray(field)
      },
      cleanFilter(key){
        this.form[key] = '';
        this.key++;
        this.$emit('change')
      },
      getNameFilter(key){
        //return key;
        //return this.pageConfg.filters.form[key].label;
        if(this.labels && this.labels[key]){
          return this.labels[key];
        }
        return key;
      },
      showFilters() {
        const filters = [];
        for(const i in this.form) {
          if(typeof this.form[i] !== 'undefined'){

            if(this.form[i] && this.form[i].id ){
              filters.push({
                    key: i,
                    name: this.getNameFilter(i),
                    value: this.form[i].name
                  })
            }else{
              if(this.form[i] && this.isDate(this.form[i])){
                let v = ''
                if(this.form[i].from){
                  v = ' (desde)' + this.form[i].from;
                }
                if(this.form[i].to){
                  if(v == ''){
                    v = '(hasta)' + this.form[i].to;
                  }else{
                    v += ' - (hasta)' + this.form[i].to;
                  }
                }
                if(v != ''){
                  filters.push({
                      key: i,
                      name: this.getNameFilter(i),
                      value: v
                    })
                }
              }else{
                if(this.form[i] == 'true' || this.form[i] == 'false'){
                  if(this.form[i] == 'true'){
                      filters.push({
                        key: i,
                        name: this.getNameFilter(i),
                        value: 'Si'
                      })
                  }else{
                    filters.push({
                        key: i,
                        name: this.getNameFilter(i),
                        value: 'No'
                      })
                  }
                }else{
                  if(this.form[i] && !this.isDate(this.form[i])) {
                    if(this.isArray(this.form[i])){
                      const mapVal = this.form[i].map( item => item.name);
                      const str = mapVal.join(', ');

                      filters.push({
                        key: i,
                        name: this.getNameFilter(i),
                        value: str
                      })

                    }else{
                      filters.push({
                        key: i,
                        name: this.getNameFilter(i),
                        value: this.form[i]
                      })
                    }
                  }
                }
              }
            }
          }
        }
        this.key++;
        //this.filters = filters;
        return filters;
        //
      }
      
    }
  }
</script>
