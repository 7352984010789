<template>
  <div>
    
    <v-switch v-model="status" @change="change" :label="statusLabelComputed" :loading="loading" :disabled="disabled" dense></v-switch>
  </div>
</template>
<script>
export default {
  name: "NgControlToggle",
  props: ["item", "controlName", "label", "url", "statusLabel"],
  data() {
    return {
      //status: false,
      loading: false,
      disabled: false
    };
  },
  computed: {
    status: {
      cache: false,
      get: function () {
        return this.item[this.controlName];
      },
      set: function (nv) {
        //this.control = nv;
      },
    },
    statusLabelComputed(){
      if(this.statusLabel){
        let lb = '';
        for(const i in this.statusLabel){
          if(this.statusLabel[i]){
            return i;
          }
        }
      }
      return this.label;
    }
  },
  methods: {
      change(evt){
          this.toggle(evt);
      },
      toggle(val){
          this.loading = true;
          this.disabled = true;
          const params = {};
          params[this.controlName] = val;
          this.$http.post(this.url+'/'+this.item.id, params).then((response) => {
              this.loading = false;
              this.disabled = false;
              if(response.success){
                  this.$emit('input', response.item[this.controlName])
              }
          }).catch((err) => {
              this.loading = false;
              this.disabled = false;
          })
      }
  }
};
</script>
