<template>
  <div class="d-flex" :id="'div-select-' + label">
    <validation-provider
      v-slot="{ errors }"
      :name="label"
      :rules="rules"
      style="width: 100%"
    >
      <v-select
        :value="value"
        :id="$attrs.id || 'input-select-' + label"
        :class="$attrs.class || ' select-' + label"
        :loading="loading"
        v-bind="{ ...config, ...$attrs }"
        :items="items"
        :label="$t(label) + labelRequired()"
        :error-messages="errors"
        :value-comparator="compare"
        v-on="$listeners"
        @input="inputEvt"
        return-object
      >
        <template v-slot:item="{ item }">
          <span v-if="item.name" :id="'select-option-'+item.id">{{ item.name }}</span>
          <span v-else  :id="'select-option-'+item.id">{{ item.nombre }}</span>
        </template>
        <template v-slot:selection="{ item }">
          <span v-if="item.name" :id="'select-option-'+item.id">{{ item.name }}</span>
          <span v-else  :id="'select-option-'+item.id">{{ item.nombre }}</span>
        </template></v-select
      ></validation-provider
    >
    <slot></slot>
    <ng-help v-if="help" :type="help.type" :value="help.value" :label="label" />
  </div>
</template>
<script>
import AbstractField from "./AbstractField";

export default {
  name: "NgSelect",
  mixins: [AbstractField],
  data() {
    return {
      items: [],
      loading: false,
    };
  },
 /* $_veeValidate: {
    name() {
      return this.label;
    },
    value() {
      return this.value;
    },
  },*/
  props: {
    url: {
      type: String,
    },
    dependsOf: {
      type: Object,
    },
    cacheOff: {
      type: Boolean,
      default: false,
    },
    notAutoLoad: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (!this.items || this.items.length == 0) {
      if (!this.notAutoLoad) {
        this.loadItems();
      }
    }
    if(this.dependsOf && this.value && this.value.id){
      this.loadItems();
    }
    if (this.value && this.value.id && !this.items) {
      this.items = [JSON.parse(JSON.stringify(this.value))];
      this.$emit("change", this.value);
    }
  },
  watch: {
    value() {
      if (this.value && this.value.id && this.items && this.items.length == 0) {
        this.$nextTick().then(() => {
          this.items = [JSON.parse(JSON.stringify(this.value))];
        });
      }
      this.$emit("change", this.value);
    },
  },
  methods: {
    inputEvt(evt) {
      this.$emit("input", evt);
    },
    reload() {
      this.$nextTick().then(() => {
        this.loadItems();
      });
    },
    loadItems() {
      const params = {};
      if (this.dependsOf) {
        for (const index in this.dependsOf) {
          params[index] = this.dependsOf[index];
        }
      }

      let list = this.$store.getters.getList(this.getUrlQuery(params));
      //console.log(list, this.cacheOff)
      if (!list || this.cacheOff) {
        this.queryOptions();
        //this.$emit('change', this.value)
      } else {
        this.items = list.items;
        //this.$emit('change', this.value)
      }
    },
    queryOptions(v) {
      const params = {};
      if (this.dependsOf) {
        for (const index in this.dependsOf) {
          params[index] = this.dependsOf[index];
          if (!params[index]) {
            this.loading = false;
            return;
          }
        }
      }
      this.loading = true;
      this.$http
        .get(this.getUrlQuery(), params)
        .then((data) => {
          this.$store.commit("addList", {
            path: this.getUrlQuery(params),
            items: data.items,
          });
          this.$nextTick().then(() => {
            this.loading = false;
            this.items = data.items;
          });
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
