<template>
  <!--   v-bind="$attrs"  :permanent="!$store.getters.mobile"  -->
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    color="white"
    :src="barImage"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    mobile-breakpoint="600"
    app
  >
    <img
      src="/assets/images/brand/primary-horizontal-logo.svg"
      class="d-lg-none d-block ma-3 mx-auto"
      width="180"
    />
    <template v-slot:img="props">
      <v-img :gradient="`to bottom, ${barColor}`" v-bind="props" />
    </template>

    <v-list class="drawer-menu" id="main-sidebar" expand nav>
      <!-- <div class="mx-auto mb-4 d-flex justify-center flex-column">
        <v-list-item-avatar v-if="$store.getters.company && $store.getters.company.logo" size="60">

          <img :src="$http.getBaseUrlPublicFiles() + $store.getters.company.logo" :alt="$store.getters.company.name" />
        </v-list-item-avatar>
        <v-list-item v-if="$store.getters.company" class="pa-0 ma-0 primary--text text-center">
          <v-list-item-content v-if="$store.getters.company">
            <v-list-item-title v-text="$store.getters.company.name"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div> -->
      <template v-for="item in menu">
        <v-list-item
          v-if="item && item.children && item.children.length == 0"
          dense
          :to="item.path"
          v-bind:key="item.id"
        >
          <v-list-item-title dense>
            <v-icon v-if="item.icon" color="primary">{{
              `${item.icon}`
            }}</v-icon>
            <v-icon v-else>mdi-menu</v-icon> {{ item.name }}
          </v-list-item-title>
        </v-list-item>

        <template v-else>
          <v-list-group dense v-bind:key="item.id">
            <template v-slot:activator>
              <v-list-item-title dense>
                <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
                <v-icon v-else>mdi-menu</v-icon>
                {{ item.name }}
              </v-list-item-title>
            </template>

            <template v-for="subitem in item.children">
              <v-list-group
                v-if="
                  subitem && subitem.children && subitem.children.length > 0
                "
                no-action
                sub-group
                dense
                class="pl-2 mb-0"
                v-bind:key="subitem.id"
              >
                <template v-slot:activator>
                  <v-list-item dense>
                    <v-list-item-content dense>
                      <v-list-item-title dense>
                        <v-icon v-if="subitem.icon">{{ subitem.icon }}</v-icon>
                        <v-icon v-else class="ml-2 mr-1"
                          >mdi-circle-medium</v-icon
                        >
                        {{ subitem.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <!-- v-on:click="navigate(subitem2.url)" -->
                <v-list-item
                  v-for="(subitem2, i) in subitem.children"
                  :key="i"
                  dense
                  link
                  :to="subitem2.path"
                  class="pl-4"
                >
                  <v-list-item-title dense>
                    <v-icon v-if="subitem2.icon" class="ml-2">{{
                      subitem2.icon
                    }}</v-icon>
                    <v-icon v-else class="ml-2 mr-1">mdi-circle-medium</v-icon>
                    {{ subitem2.name }}
                  </v-list-item-title>
                </v-list-item>
              </v-list-group>
              <!-- v-on:click="navigate(subitem.url)" -->
              <v-list-item
                v-else
                dense
                :to="subitem.path"
                class="grey--text pl-4"
                v-bind:key="subitem.id"
              >
                <v-list-item-title dense>
                  <v-icon v-if="subitem.icon" class="ml-2 mr-1">{{
                    subitem.icon
                  }}</v-icon>
                  <v-icon v-else class="ml-2 mr-1">mdi-circle-medium</v-icon>
                  {{ subitem.name }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-list-group>
        </template>
      </template>
      <v-list-item dense>
        <v-list-item-content dense>
          <v-list-item-title dense>
            <v-icon>mdi-play-circle-outline</v-icon> {{ $t("tutorials") }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="logout()" dense>
        <v-list-item-content dense>
          <v-list-item-title dense>
            <v-icon>mdi-logout-variant</v-icon> Salir
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>


<script>
// Utilities
import { mapState } from "vuex";

export default {
  name: "LayoutPosDrawer",

  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    items: [
      {
        id: "3e567128-c82c-11ea-b8eb-02027d",
        name: "POS",
        sys_menu_id: null,
        icon: "mdi-cast-variant",
        path: "/pos",
        children: [],
      },
      {
        id: "7123e568-2c020c827d-b8eb-11ea",
        name: "Turnos",
        sys_menu_id: null,
        icon: "mdi-store-outline",
        path: "/pos/close-turn",
        children: [
          {
            id: "3e567128-c82c02027d-11ea-b8eb",
            name: "Cerrar Turno",
            sys_menu_id: null,
            icon: "mdi-store-minus-outline",
            path: "/pos/close-turn",
            children: [],
          },
          {
            id: "3e467339-d82c13527d-12fa-b8fc",
            name: "Turnos",
            sys_menu_id: null,
            icon: "mdi-store-outline",
            path: "/pos/turns",
            children: [],
          },
        ],
      },
      {
        id: "3e567128-c82c-11ea-b354-0202asdd",
        name: "Ordenes",
        sys_menu_id: null,
        icon: "mdi-file-document-multiple-outline",
        path: "/pos",
        children: [
          /* {
            "id": "02027dc1a8b0-f41c02ee-08cc-11eb-ac2d",
            "name": "Ordenes",
            "sys_menu_id": "3e567128-c82c-11ea-b8eb-020223dcx",
            "icon": 'mdi-text-box-minus-outline',
            "path": "/pos/local-orders",
            "children": []
          },*/
          {
            id: "02827ec1a8b0-f41c58ef-08cc-11eb-ac2d",
            name: "Ordenes",
            sys_menu_id: "3e567128-c82c-11ea-b8eb-020223dcx",
            icon: "mdi-text-box-check-outline",
            path: "/pos/server-orders",
            children: [],
          },
        ],
      },

      // {
      //   id: "3e567128-c82c-11ea-b354-2003dddd",
      //   name: "Egresos",
      //   sys_menu_id: null,
      //   icon: "mdi-text-box-minus-outline",
      //   path: "/pos",
      //   children: [
      //     {
      //       id: "02827ec1a8b0-f41c58ef-08cc-11eb-ac3d",
      //       name: "Crear (En Borrador)",
      //       sys_menu_id: "3e567128-c82c-11ea-b8eb-020223dcx",
      //       icon: "mdi-cloud-outline",
      //       path: "/pos/expense-draft",
      //       children: [],
      //     },
      //     {
      //       id: "02827ec1a8b0-f41c58ef-08cc-11eb-aa4d",
      //       name: "Aprobados",
      //       sys_menu_id: "3e567128-c82c-11ea-b8eb-020223dcx",
      //       icon: "mdi-cloud-outline",
      //       path: "/pos/expense-finalized",
      //       children: [],
      //     },
      //     {
      //       id: "02827ec1a8b0-f41c58ef-08cc-11eb-ab4d",
      //       name: "Anulados",
      //       sys_menu_id: "3e567128-c82c-11ea-b8eb-020223dcx",
      //       icon: "mdi-cloud-outline",
      //       path: "/pos/expense-annulled",
      //       children: [],
      //     },
      //   ],
      // },

      // {
      //   id: "3e567128-c82c-11ea-b322-2003dddd",
      //   name: "Notas Crédito (DE)",
      //   sys_menu_id: null,
      //   icon: "mdi-note-minus-outline",
      //   path: "/pos",
      //   children: [
      //     {
      //       id: "3e567128-f41c58ef-08cc-11eb-ac3d",
      //       name: "Crear (En Borrador)",
      //       sys_menu_id: "3e567128-c82c-11ea-b8eb-020223dcx",
      //       icon: "mdi-cloud-outline",
      //       path: "/pos/de-credit-note-draft",
      //       children: [],
      //     },
      //     {
      //       id: "3e567128-f41c58ef-08cc-11eb-aa4d",
      //       name: "Aprobados",
      //       sys_menu_id: "3e567128-c82c-11ea-b8eb-020223dcx",
      //       icon: "mdi-cloud-outline",
      //       path: "/pos/de-credit-note-finalized",
      //       children: [],
      //     },
      //     {
      //       id: "3e567128-f41c58ef-08cc-11eb-ab4d",
      //       name: "Anulados",
      //       sys_menu_id: "3e567128-c82c-11ea-b8eb-020223dcx",
      //       icon: "mdi-cloud-outline",
      //       path: "/pos/de-credit-note-annulled",
      //       children: [],
      //     },
      //   ],
      // },
      /*{
        "id": "3e567128-c82c-11ea-b354-0202asd1",
        "name": "Pedidos Por Recibir",
        "sys_menu_id": null,
        "icon": 'mdi-file-document-edit-outline',
        "path": "/pos/orders-receive",
        "children": []
      }, */
      {
        id: "3e5846728-c82c-b8eb-11ea",
        name: "Configuración",
        sys_menu_id: null,
        icon: "mdi-cog-outline",
        path: "/pos/configuration",
        children: [
          {
            id: "3e567128-c82c-11ea-b8eb",
            name: "Caja",
            sys_menu_id: null,
            icon: "mdi-cast-variant",
            path: "/pos/configuration",
            children: [],
          },
          {
            id: "3e567239-d82c-11fa-b8eb",
            name: "Herramientas",
            sys_menu_id: null,
            icon: "mdi-wrench-outline",
            path: "/pos/tools",
            children: [],
          },
        ],
      },
    ],
  }),
  created() {
    this.$auth.loadInfoSession();
  },
  computed: {
    ...mapState(["barColor", "barImage"]),
    drawer: {
      get() {
        return this.$store.state.shared.drawer;
      },
      set(val) {
        this.$store.commit("setDrawer", val);
      },
    },
    menu() {
      //return this.$store.getters.menu;
      return this.items;
    },
    auth() {
      return this.$store.getters.auth;
    },
    profile() {
      return {
        avatar: true,
        title: this.$t("avatar"),
      };
    },
  },

  methods: {
    logout() {
      this.$store.dispatch("logoutAuth");
    },
    getPubliPath() {
      return process.env.VUE_APP_BASECONFIG_URL_PUBLIC_FILES;
    },
  },
};
</script>

<style lang="scss">
.v-navigation-drawer {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);

  &.v-navigation-drawer--mini-variant {
    .v-list-group__items {
      display: none;
    }
  }

  position: fixed !important;

  .v-list {
    padding: 8px;
  }

  .v-list-item {
    font-weight: 300;
    padding-left: 0.825rem;
    border-radius: 4px;

    &__icon--text,
    &__icon:first-child {
      justify-content: center;
      text-align: center;
      width: 20px;
    }

    &--active {
      color: var(--v-primary-darken1);

      &:before {
        background: linear-gradient(
          90deg,
          rgba(36, 169, 225, 1) 50%,
          #fff 100%
        );
        border-radius: 4px;
      }

      .v-list-item__title {
        font-size: 14px;
        font-weight: 600;
        color: #3c3c3b;
      }
    }
  }

  .v-list-group {
    margin-bottom: 8px;

    &--active {
      .v-list-group__header {
        font-weight: 400;
        background: linear-gradient(
          90deg,
          rgba(36, 169, 225, 0.07) 30%,
          #fff 100%
        );

        &:before {
          background: linear-gradient(90deg, rgb(36, 169, 225) 30%, #fff 100%);
          opacity: 0.03;
        }
      }
    }

    &--sub-group {
      .v-list-group__header__prepend-icon {
        order: 2;
        margin-right: 0px;
      }

      .sicon {
        margin-right: 8px;
      }

      .v-list-group__header {
        padding-left: 12px;

        &.v-list-item--active {
          color: rgba(0, 0, 0, 0.87) !important;
        }
      }
    }

    &.second-dd {
      > .v-list-group__items {
        border-left: 1px solid rgba(0, 0, 0, 0.09);
      }
    }
  }

  .v-navigation-drawer__border {
    display: none;
  }
}

#main-sidebar {
  width: 100%;

  .v-icon {
    color: #24a9e1;
  }

  .v-list-item__title {
    font-weight: 400;
    font-size: 14px;
    color: #3c3c3b;
  }

  .v-list-group {
    &.v-list-group--active {
      .v-list-group__header {
        margin-bottom: 0px !important;
      }
    }

    > .v-list-group__items {
      border-left: 1px solid rgba(255, 255, 255, 0.09);
      padding: 0 8px !important;
      padding-bottom: 0 !important;
      margin-left: 12px;
      margin-top: 5px;

      &::before {
        content: "";
        position: absolute;
        left: -12px;
        border-top: 1px solid #000;
        top: 9px;
        width: 8px;
        height: 0;
      }

      &::after {
        content: "";
        position: absolute;
        left: -12px;
        border-left: 1px solid rgba(255, 255, 255, 0.9);
        height: 100%;
        width: 0px;
        top: 2px;
      }

      .arrow {
        display: none;
      }
    }
  }
}
</style>