<template>
  <div>
    <h3 v-if="options.label">{{ options.label }}</h3>
    <v-list dense>
      <v-list-item v-for="(item, i) in options.items" :key="i">
        <!-- ="{ active }" -->
        <template v-slot:default>
          <v-list-item-content class="text-left">
            {{ item.name }}
          </v-list-item-content>
          <v-list-item-action>
            <v-radio-group v-model="item.value" row>
              <v-radio label="No" :value="false"></v-radio>
              <v-radio label="Si" :value="true"></v-radio>
            </v-radio-group>
          </v-list-item-action>
        </template>
      </v-list-item>
    </v-list>
  </div>
</template>
<script>
import AbstractField from "./AbstractField";
export default {
  name: "NgSurvey",
  mixins: [AbstractField],
  data() {
    return {
      items: [],
    };
  },
  created() {
    this.form[this.options.name] = this.options.items;
  },
  methods: {},
};
</script>
