<template>
    <div v-if="items">
      <input type="hidden" :value="value" />
      <div class="d-flex">
        <ng-text
          readonly
          :value="textValue"
          :label="label"
          rules="required"
          hide-details
          @click:clear="clear"
        ></ng-text>
  
        <v-btn icon @click="dialog = true"><v-icon>mdi-magnify</v-icon> </v-btn>
      </div>
      <!--v-radio-group :value="value" v-if="items" v-model="selected" -->
      <v-dialog v-model="dialog" persistent width="500">
        <v-card>
          <v-app-bar color="primary">
            <v-toolbar-title class="text-h6 white--text pl-0">
              {{ $t("Seleccione un método de pago") }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              color="white"
              icon
              @click="dialog = false"
              :title="$t('Cancel')"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
  
  
          </v-app-bar>
  
          <v-app-bar color="primary">
         
  
            <v-text-field
              v-model="search"
              label="Buscar"
              dark
              flat
              solo-inverted
              hide-details
              clearable
              clear-icon="mdi-close-circle-outline"
            ></v-text-field>
          </v-app-bar>
          <v-card-text style="max-height: 45vh; overflow-y: auto">
            <v-treeview
              style="width: 100%"
              selection-type="leaf"
              :active.sync="actives"
              open-all
              activatable
              return-object
              :search="search"
              :filter="filter"
              :items="items"
            >
              <template v-slot:prepend="{ active }">
                <v-icon color="primary" v-if="active">mdi-check</v-icon>
              </template>
            </v-treeview>
          </v-card-text>
          <v-divider></v-divider>
  
          <v-card-actions>
            <v-spacer></v-spacer>
  
            <v-btn outlined small color="error" @click="dialog = false">{{
              $t("Cerrar")
            }}</v-btn>
            <v-btn @click="save" small color="primary">{{ $t("Aceptar") }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!--/v-radio-group-->
    </div>
  </template>
    <script>
  export default {
    data: () => ({
      actives: [],
      dialog: false,
      items: null,
      search: "",
      caseSensitive: false,
    }),
    props: ["value", "url", "label", "cacheOff", "dependsOf"],
    computed: {
      filter() {
        return  (item, search, textKey) => item[textKey].toLowerCase().indexOf(search.toLowerCase()) > -1;
      },
      textValue() {
        if (this.value && this.value.name) {
          return this.value.name;
        }
        return "";
      },
    },
    watch: {
      value: {
        deep: true,
        handler() {
          if (this.value) {
            this.actives = [this.value];
          }
        },
      },
    },
    created() {
      this.loadItems();
    },
    methods: {
      clear() {
        this.$emit("input", null);
      },
      save() {
        //console.log(this.actives)
        if (this.actives && this.actives.length == 0) {
          this.clear();
        } else {
          this.$emit("input", {
            id: this.actives[0].id,
            name: this.actives[0].name,
          });
        }
        this.dialog = false;
      },
      reload() {
        this.$nextTick().then(() => {
          this.loadItems();
        });
      },
      loadItems() {
        const params = {};
        if (this.dependsOf) {
          for (const index in this.dependsOf) {
            params[index] = this.dependsOf[index];
          }
        }
  
        this.queryOptions();
  
        /*let list = this.$store.getters.getList(this.getUrlQuery(params));
          if (!list && !this.cacheOff) {
            this.queryOptions();
          } else {
            this.items = list.items;
          }*/
      },
      serializeQuery(params) {
        const value = {};
        for (const i in params) {
          if (Array.isArray(params[i])) {
            value[i] = params[i].map((item) => {
              if (item.id) {
                return { id: item.id };
              }
              return item;
            });
          } else {
            if (params[i] && params[i].id) {
              value[i] = { id: params[i].id };
            } else {
              value[i] = params[i];
            }
          }
        }
        return "params=" + JSON.stringify(value);
      },
      getUrlQuery(params) {
        return this.url + "?" + this.serializeQuery(params);
      },
      queryOptions(v) {
        const params = {};
        if (this.dependsOf) {
          for (const index in this.dependsOf) {
            params[index] = this.dependsOf[index];
            if (!params[index]) {
              this.loading = false;
              return;
            }
          }
        }
        this.loading = true;
        this.$http
          .get(this.getUrlQuery(params))
          .then((data) => {
            this.$store.commit("addList", {
              path: this.getUrlQuery(params),
              items: data.items,
            });
            this.$nextTick().then(() => {
              this.loading = false;
              this.items = data.items;
            });
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
          })
          .finally(() => {
            this.loading = false;
          });
      },
    },
  };
  </script>
      