class ItemLine {
	/*constructor(){
	}*/

	static getTotal(item) {
		let total = parseFloat(item.total_amount);
		if (item.sub_items) {
			for (const sub of item.sub_items) {
				total += parseFloat(sub.total_amount);
			}
		}
		return total;
	}

	static getSubtotal(item) {
		let total = parseFloat(item.subtotal_amount);
		if (item.sub_items) {
			for (const sub of item.sub_items) {
				total += parseFloat(sub.subtotal_amount);
			}
		}
		return total;
	}

	static getTotalDiscount(item) {
		if (item.discount_amount) {
			return parseFloat(item.discount_amount);
		}
		return 0;
	}

	static getTotalRealDiscount(item) {
		if (item.real_discount) {
			return parseFloat(item.real_discount);
		}
		return 0;
	}

	static getTotalTaxes(item) {
		/*if(item.tax_amount){
			return parseFloat(item.tax_amount);
		}*/
		let total = parseFloat(item.tax_amount);
		if (item.sub_items) {
			for (const sub of item.sub_items) {
				total += parseFloat(sub.tax_amount);
			}
		}
		return total;
	}



	static async createItem(posManager, id, product, variant, qt, note, options, discount, new_price, price_list_id) {
		//getSalePrice
		//let basePrice = posManager.getPrice(product);

		const { priceList } = posManager.getPriceListForProduct(product, price_list_id);
		// precio original
		//let priceWithTaxes = posManager.getSalePrice(product, discount, price_list_id);
		let priceWithTaxes = posManager.getSalePrice(product, 0, price_list_id);
		//console.log(priceWithTaxes, new_price,  '...........................')

		if(new_price != -1){
			priceWithTaxes = new_price;
		}

		const itemPriceList = product.prices[priceList.id];
		//console.log(priceWithTaxes, itemPriceList)
		let realDiscountAmount = 0;
		let discountAmount = 0;
		let discountInfo = null;
		let pricelAmount = priceWithTaxes;
        let comercialPrice = pricelAmount;
		let priceOriginal = pricelAmount;
		//console.log(discountAmount)
		let taxes = null;
		let taxAmount = 0;
		// si tiene descuentos busca la base original para aplicar los descuentos y luego recalcula los impuestos nuevos
		//if (discount) {
			if (itemPriceList.acc_tax_id) {
				taxes = await posManager.calculateTaxesInclude((priceWithTaxes * qt), [itemPriceList.acc_tax_id]);
				let initialValue = 0;
				if (taxes) {
					taxAmount = taxes.reduce((accumulator, item) => {
						if (!accumulator) {
							accumulator = 0;
						}
						return accumulator + item.tax_amount;
					}, initialValue);
				}
			}
		//}
		// console.log('............', taxes, taxAmount);
		comercialPrice = (comercialPrice * qt) - taxAmount;
		let basePrice = ((priceWithTaxes * qt) - taxAmount);
		let lineExtensionAmount = posManager.round(basePrice);
		let priceAmount = priceWithTaxes;
		let percentageDiscount = 0;
		// console.log('............', priceWithTaxes, taxAmount, basePrice)
		if (discount) {
			realDiscountAmount = posManager.round(posManager.calculateDiscount((priceWithTaxes * qt), discount));
			discountAmount = posManager.round(posManager.calculateDiscount(basePrice, discount));
			percentageDiscount = discount;
			basePrice = basePrice - discountAmount;			
		}			
		discountInfo = { amount: discountAmount, 'base_amount': lineExtensionAmount,
			"charge_indicator": false,
			"allowance_charge_reason": "DESCUENTO GENERAL",  /*base: basePrice * qt*/ 
		};

		//calcula los impuestos sobre el valor final
		if (itemPriceList.acc_tax_id) {
			taxes = await posManager.calculateTaxes(basePrice, [itemPriceList.acc_tax_id]);
			let initialValue = 0;
			taxAmount = 0;
			if (taxes) {
				taxAmount = taxes.reduce((accumulator, item) => {
					if (!accumulator) {
						accumulator = 0;
					}
					return accumulator + item.tax_amount;
				}, initialValue);
			}
		}

		if(!taxes){
			taxes = await posManager.setTaxDefault(basePrice);
		}

		let basePriceRound = posManager.round(basePrice);
		//console.log(taxes);
		const item = {
			'id': id,
			//'product_id': product.id,
			'product': {
				"id": product.id,
				"name": product.name,
				"category_id": product.category_id,
				"category": product.category,
				"reference": product.reference,
				"unity": product.unity,
				"kit-items": product['kit-items'],
				//"inv_unity_id": product.unity.id,
				"product_type": product.type,
				"has_inventory": product.has_inventory,
				"is_scale": product.is_scale
			},
			'price_list_id': price_list_id,
			'product_variant': variant,
			//'code': product.reference, // referencia del producto
			//'description': product.name, // nombre del producto
			//'variant_id': (variant && variant.id) ? variant.id : '',
			//'variant_name': (variant && variant.name) ? variant.name : '',
			//'unit_measure_id': '', // unidad de medida : integer
			'unit_price': priceOriginal,
			'quantity': qt,
			// 'price_amount': (priceOriginal * qt) - taxAmount , //  precio de venta original sin impuestos
			'price_amount': priceAmount, //  precio de venta original sin impuestos
			'line_extension_amount': lineExtensionAmount, // (precio base ) - descuento unitario
			'tax_amount': taxAmount,  // impuesto unitario por cantidad
			'real_discount': realDiscountAmount, // descuentao unitario por cantidad			
			'discount_amount': discountAmount, // descuentao unitario por cantidad
			'subtotal_amount': basePriceRound,
			'item_amount': priceOriginal,
			//Valor total de la línea: Cantidad x Precio Unidad menos descuentos más recargos que apliquen para la línea.
			'total_amount': (basePriceRound + taxAmount),
			//Indica que el elemento es un Cargo y no un descuento: Cargo es true, es un Débito aumenta el valor de la item. Descuento es false, un Crédito descuenta el valor del ítem
			//El elemento solamente puede identificar una de las información.
			'free_of_charge_indicator': false,
			'note': note,
			'options': options,
			//
			'percentage_discount': percentageDiscount,
			'allowance_charges': [discountInfo],
			'sub_items': null,
			'taxes': taxes,
			'printed': false,
			'_editing': false
			//se usa para saber si ya se imprimio esta linea en la opcion de impresora remota
		};

		return item;

	}
}

export default ItemLine;