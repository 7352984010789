<template>
  <v-app-bar app color="white" class="app-bar">
    <slot name="menu">
      <v-app-bar-nav-icon
        @click.stop="$store.commit('toggle')"
        
      ></v-app-bar-nav-icon>
    </slot>
    <!--v-if="$store.getters.mobile"-->
    <!--v-toolbar-title>{{ $t(pageConfg.title) }}</v-toolbar-title-->

    <slot name="title"></slot>
    <v-spacer></v-spacer>
    <slot name="help"></slot>
    <!--template v-if="pageConfg.module_actions">
    <v-btn v-for="act in pageConfg.module_actions" fab  x-small color="secondary"  elevation="1"  v-on:click="executeModuleAction(act)">
      <v-icon>{{getActionIcon(act)}}</v-icon>
    </v-btn>
    </template-->
    <!--div class="main-toolbar" v-if="$store.getters.mobile"-->
    <!--v-btn
                @click.stop="showMenu = true"
                icon
              ><v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>
     <v-menu
            bottom
            left
            v-model="showMenu"
          >
        
            <slot name="actions">
              <v-list>
              <v-list-item>
                <v-list-item-title>action 1</v-list-item-title>
              </v-list-item>
            </v-list>
            </slot>
          </v-menu-->

    <!--/div-->
    <div class="main-toolbar">
      <v-btn v-if="showCancel" small icon outlined @click="cancel()" class="mr-1"
        ><v-icon>mdi-close</v-icon></v-btn
      >
      <slot name="actions"> </slot>
      
    </div>
  </v-app-bar>
</template>

<style>
.app-bar, .v-toolbar__content {
  height: 70px !important;
}
.v-sheet.v-app-bar.v-toolbar:not(.v-sheet--outlined) {
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.02) !important;
}
</style>

<script>
export default {
  name: "default-app-bar",
  data: () => ({
    showMenu: false,
    items: [
      { title: "Click Me" },
      { title: "Click Me" },
      { title: "Click Me" },
      { title: "Click Me 2" },
    ],
  }),
  mounted() {},
  props: ["config", "showCancel"],
  methods: {
    cancel() {
      this.$router.go(-1);
    },
    toogleDrawer() {
      this.$store.commit("setDrawer", !this.$store.getters.drawer);
    },
  },
};
</script>
<style scoped>

.main-toolbar{
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>