<template>
<ValidationObserver v-slot="{ invalid }" ref="formPasswd">
    <div>
        
        <ng-passwd v-model="password" label="Contraseña" rules="required|min:8" />

    <div class="text-right">
        <v-btn color="primary" text outlined small :disabled="invalid" @click="save">
        {{$t('Asignar Contraseña')}}
        </v-btn>
    </div>
    </div>
</ValidationObserver>
</template>
<script>
  export default {
    name: 'NgChangePasswd',
    data(){
      return {
        password: ''
      }
    },
    props: ['item'],
    methods: {
        save(){
            this.$http.post('api/v1/user/'+this.item.id, { password: this.password}, true, true).then(response => {
                if(response.success){
                    this.password = '';
                    this.$refs.formPasswd.reset();
                    this.$store.commit('setMessage', this.$t('Lcontraseña se modifico correctamente.'))
                }
            });
        }
    }
  }
</script>
