<template>
<div style="width: 100%;" class="d-flex flex-column align-center justify-center">
  <!--label for="image_upload">Upload image:</label-->
  <div class="upload-btn-wrapper">
      <v-btn class="btn" small>{{$t('Seleccionar')}}</v-btn>
       <input type="file" id="image_upload" name="image_upload" multiple accept='image/*'
        @change="loadImage" class="custom-file-input2" ref="file" />
  </div>
 
  <img src="@/assets/product-blank.png" v-show="!path && !previewBase64" class="mt-4" style="width: 164px; height: auto">
  <img :src="getPath()" v-if="path != '' && !previewBase64" class="mt-4" style="width: 164px; height: auto">

  <!--canvas  height="auto" v-show="previewBase64" width="164" id="image_preview"  class="mt-4" ></canvas-->
  <canvas  height="512" width="512" id="image_large_canvas"  class="mt-4" style="display: none"></canvas>

</div>
</template>
<script>
  export default {
    name: 'ProductImageUpload',
    data: () => ({
      changeImage: false,
      base64: null,
      previewBase64: null
      //path: 'https://drive.google.com/uc?export=view&id=145FrgOPcRvKxyacl56bv-7mhFFPDfRkb'
    }),
    props: [ 'item', 'path', 'url'],
    mounted(){

    },
    watch: {
      item: {
        deep: true,
        immediate: true,
        handler (val) {
          this.upload();
        }
      }
    },
    methods: {
      getPath(){
        /*if (this.url_upload) {
          //return this.$http.getBaseUrlImagesCategoryProducts() + this.path;
        }else{
          //return this.$http.getBaseUrlImagesProducts() + this.path;
        } */
        return this.path;       
      },
      loadImage($event){
        var file = document.getElementById("image_upload").files[0]
        if (file.type.match('image.*')) {
          var fr = new FileReader();
          fr.onload = (fr_e) => {
            var canvas = document.getElementById('image_large_canvas');
            var context = canvas.getContext('2d');
            var imageObj = new Image();
            imageObj.onload = ( io_e ) => {
              this.base64 = this.resize(context, io_e, canvas);
              
              this.upload();
            };
            imageObj.src = fr_e.target.result;


            var canvasPreview = document.getElementById('image_preview');
            var contextPreview = canvasPreview.getContext('2d');
            var imageObjPreview = new Image();
            imageObjPreview.onload = ( io_e ) => {
              this.resize(contextPreview, io_e, canvasPreview)
              this.previewBase64 = this.resize(context, io_e, canvas);
            };
            imageObjPreview.src = fr_e.target.result;

          }
          fr.readAsDataURL(file);
        }
      },
      resize(context, io_e, canvas){
        var image_height = io_e.target.naturalHeight;
        var image_width = io_e.target.naturalWidth;
        var canvas_height = canvas.getAttribute("height");
        var canvas_width = canvas.getAttribute("width");
        var image_ratio = 1.0 * image_width / image_height;
        //var canvas_ratio = 1.0 * canvas_width / canvas_height;

        if(image_width < 512){
          canvas_width = image_width;
        }

        var image_within_canvas_height, image_within_canvas_width = 10;
        //if (canvas_ratio <= image_ratio) {
          image_within_canvas_width = canvas_width;
          image_within_canvas_height = canvas_width / image_ratio;
       /* } else {
          image_within_canvas_width = canvas_width;
          image_within_canvas_height = canvas_width / image_ratio;
        }*/

        canvas.setAttribute("width", Math.round(image_within_canvas_width))
        canvas.setAttribute("height", Math.round(image_within_canvas_height))
        context.drawImage(io_e.target, 0, 0, image_within_canvas_width, image_within_canvas_height);
        return canvas.toDataURL('image/jpeg', 0.9);

      },
      upload(){
        if (this.url) {
          if(this.item && this.item.id && this.base64){
            this.$http.post(this.url+'/'+this.item.id, {base64: this.base64 }, true, false).then((response) => {
                if(response.success){
                  //this.$emit('change', response.image)
                  this.$emit('saved', response.image);
                  this.$refs.file.value = "";
                }
            }).catch((e) => {
              this.base64 = null;
              this.previewBase64 = null;
            })
          }
        } 
      }  
    }
  }
</script>
<style scoped>
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.btn {
  border: 2px solid gray;
  color: gray;
  background-color: white;
  padding: 8px 20px;
  border-radius: 8px;
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
</style>