<template>
  <div>
    <v-list-item>
      <v-list-item-content class="text-left">
        {{ label }}
      </v-list-item-content>

      <v-list-item-action>
        <v-switch
          :value="value"
          v-bind="{ ...config, ...$attrs }"
          inset
          flat
          @change="inputEvt"
          @input="inputEvt"
        >
          <template v-slot:label>
            <span style="color: rgba(0, 0, 0, 0.87) !important" v-if="!value"
              >No</span
            >
            <span style="color: rgba(0, 0, 0, 0.87) !important" v-else>Sí</span>
          </template>
        </v-switch>
      </v-list-item-action>
    </v-list-item>
  </div>
</template>
<script>
import AbstractField from "./AbstractField";
export default {
  name: "NgCheck",
  mixins: [AbstractField],
};
</script>
