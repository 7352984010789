<template>
  <v-dialog
    v-model="dialog"
    :light="true"
    :dark="false"
    persistent
    max-width="290"
  >
    <ValidationObserver v-slot="{ invalid }" ref="form">
      <v-card>
        <v-card-title class="headline"></v-card-title>

        <v-card-text
          >{{ text }}
          <ng-textarea v-model="note" rules="required|min:8" label="Nota"></ng-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="close(false)" text>{{
            $t("Cancelar")
          }}</v-btn>
          <v-btn
            color="primary"
            @click="close(note)"
            text
            :disabled="invalid"
            >{{ $t("Aceptar") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </ValidationObserver>
  </v-dialog>
</template>
<script>
import NgTextarea from "../gui/NgTextarea.vue";

export default {
  components: { NgTextarea },
  data() {
    return {
      dialog: true,
      note: "",
    };
  },
  props: ["text", "resolve"],
  methods: {
    close(accept) {
      this.resolve(accept);
      this.$destroy(this.$el);
    },
  },
};
</script>
