<template>
  <div class="d-flex">
    <v-text-field
      type="color"
      v-model="value"
      v-bind="{ ...config, ...$attrs }"
      :label="$t(label) + labelRequired()"
      @input="inputEvt"
      v-on="$listeners"
      :error="error"
      :error-messages="errorMessages"
      colorpick-eyedropper-active="true"
      class="input-color"
    />

    <ng-help v-if="help" :text="help" />
  </div>
</template>
<script>
import AbstractField from "./AbstractField";
export default {
  name: "NgColor",
  mixins: [AbstractField],
};
</script>
<style>
.input-color input {
  padding: 0px !important;
}
</style>