

export default {
    changeLocationHref(){
        if (this.isAppPos()) {
            document.location.href = '/pos';
          } else {
            if (this.isAppWaiter()) {
              document.location.href = '/ventas';
            }else {
              if (this.isAppScreen()) {
                document.location.href = '/screen';
              }else{
                document.location.href = '/pos';
              }
            }
          }
    },
    redirectUrl($router){
        if (this.isAppPos()) {
            $router.push('/pos');
          } else {
            if (this.isAppWaiter()) {
              $router.push('/ventas');
            }else {
              if (this.isAppScreen()) {
                $router.push('/screen');
              }else{
                $router.push('/pos');
              }
            }
          }
    },
    isAppPos(){
        let expresionRegular = /(.)pos/;
        let rutaActual = window.location.href;
        if (expresionRegular.test(rutaActual)) {
          return true;
          
        } 
        return false;
    },
    isAppWaiter(){
        let expresionRegular = /(.)ventas/;
        let rutaActual = window.location.href;
        if (expresionRegular.test(rutaActual)) {
          return true;
          
        } 
        return false;
    },
    isAppScreen(){
        let expresionRegular = /(.)screen/;
        let rutaActual = window.location.href;
        if (expresionRegular.test(rutaActual)) {
          return true;
          
        } 
        return false;
    },
}