import currency from 'currency.js';
import * as moment from 'moment-timezone';
moment.locale('es');


export default {


    pathPublicImage(url) {
        return process.env.VUE_APP_BASECONFIG_URL_PUBLIC_FILES + url;
    },
    pathImage(item, field) {
        if (item && item[field]) {
            return process.env.VUE_APP_BASECONFIG_URL_PUBLIC_FILES + item[field];
        }
        return '';
    },
    currency(str, precision = 2) {
        //if (str && str != null) {
        if (str != null) {
            return currency(str, { precision: precision }).format();
        }
        //return "";
        return "0";
    },
    name(obj) {
        if (obj && obj.name != null) {
            return obj.name;

        }
        return "";
    },
    date(str, format = 'YYYY-MM-DD') {
        if (str && str != null) {
            return moment.tz(str, "America/Bogota").format(format);
        }
        return "";
    },
    fromNow(str) {
        if (str && str != null) {
            return moment.tz(str, "America/Bogota").fromNow();
        }
        return "";
    },
    datetime(str) {
        if (str && str != null) {
            //console.log(str)
            //return moment.tz(str, "America/Bogota").utc().format("YYYY-MM-DD h:mm:ss a");
            return moment.tz(str, "America/Bogota").format("YYYY-MM-DD h:mm:ss a");
        }
        return "";
    },
    currentDatetime() {
        /*console.log(moment.tz(moment(), "America/Bogota").utc().format("YYYY-MM-DD h:mm:ss a"))
        console.log(moment().utc().format("YYYY-MM-DD H:mm:ss"))
        console.log(moment().format("YYYY-MM-DD H:mm:ss"))*/
        return moment().format("YYYY-MM-DD h:mm:ss a");

    },
    localdatetime(str) {
        if (str && str != null) {
            return moment(str).utc().format("YYYY-MM-DD HH:mm:ss");
        }
        return "";
    },
    boolean(str) {
        if (str != null) {
            if (str) {
                return 'SÍ';
            } else {
                return 'NO';
            }
        }
        return "NO";
    },
    round(num) {
        let m = Number((Math.abs(num) * 100).toPrecision(15));
        return Math.round(m) / 100 * Math.sign(num);
    }
};
