export default {
    state: {
      loaded: false,
      //orderChanged: 1
    },
    getters: {
      loaded: state => state.loaded,
      //orderChanged: state => state.orderChanged,
    },
    mutations: {
  
      loaded(state, val) {
        state.loaded = val
      },

     /* orderChanged(state, val) {
        
        state.orderChanged = state.orderChanged + 1;
        //console.log('order change......................', state.orderChanged)
      },*/
  
    },
    actions: {
      loaded({ commit }, val) {
        commit('loaded', val)
      },
      /*orderChanged({ commit }, val) {
        commit('orderChanged', val)
      }*/
    }
  }