<template>
    <div>
        <ValidationObserver v-slot="{ dirty }" ref="form">
            <v-row dense v-if="item">
            <v-col cols="12" sm="6" v-for="g in groups" dense :key="g.id">
                <validation-provider style="width: 100%" :rules="{ 'required': { 'allowFalse': true } }">
                <v-checkbox
                    dense
                    :label="g.name"                    
                    :true-value="true" 
                    :false-value="false" 
                    v-model="g.selected"
                    hide-details
                    ></v-checkbox>
                </validation-provider>
            </v-col>
            </v-row>
        
        <div class="text-right">
            <v-btn color="primary" :disabled="!dirty" text outlined small @click="save">
            {{$t('Guardar')}}
            </v-btn>
        </div>
        </ValidationObserver>
    </div>
</template>
<script>
  
  export default {
    name: 'NgRolesByUser',
    // mixins: [AbstractField],
    data: () => ({
      groups: [],
      value: []
    }),
    props: ['item'],
    watch: {
        item: {
            deep: true,
            handler(){
                this.getGroups();
            }
        }
    },
    methods: {
      save(){
          this.$http.post('api/v1/auth/roles-by-user/'+this.item.id, { roles: this.groups }, true, true).then(response => {

          })
      },
  
      getGroups(){
        this.$http.get('api/v1/auth/roles-by-user/'+this.item.id, true, true).then((data) => {
          this.groups = data.roles;
        })        
      }
    }
   
  }
</script>
