export default class Configuration {

    constructor(store) {
        this.store = store;
        //this.map = null;
    }

    map(items){
        const configuration = {};
        for(const cnf of items){
            if(cnf.is_global){
                configuration[cnf.name] = cnf;
            }else{
                if(cnf.require_subsidiary){
                    configuration[cnf.name+ '_sub_' + cnf.bus_subsidiary_id] = cnf;
                }else{
                    if(cnf.object_type != ''){
                        configuration[cnf.name+ '_' + cnf.object_type + '_' + cnf.object_id] = cnf;
                    }
                }
            }
        }
        //console.log(configuration)
        this.store.dispatch('setConfigurations', configuration);
    }

}

//export default new Configuration();

/*export default {
    install(Vue, options) {
        Vue.prototype.$sourceConfig = new Configuration(options.store);
    }

}*/
