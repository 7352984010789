<template>
  <div class="d-flex">
    <v-container fluid class="pa-0">
      <v-row align="center">
        <v-col md="12">
    <validation-provider
      v-slot="{ errors }"
      :name="$t(label)"
      :rules="rules"
      style="width: 100%"
    >
    <div class="d-flex">
          <v-text-field
            label="Video Youtube"
            :value="value"
            clearable
            v-bind="{ ...config, ...$attrs }"            
            :error-messages="errors"   
            v-on="$listeners"
            @input="inputEvt"
          ></v-text-field>
          <v-btn icon color="primary" @click="save"><v-icon>mdi-content-save</v-icon></v-btn>
    </div>
          </validation-provider>
        </v-col>
      </v-row>
      <v-row align="center" v-if="haveVideo() && item">
        <v-col cols="12">
          <iframe
            name="video-youtube"
            id="video-youtube"
            :src="obtenerUrlVideo()"
            allowfullscreen
            frameborder="0"
            width="100%"
          ></iframe>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import AbstractField from "./AbstractField";
export default {
    mixins: [AbstractField],
  data: () => ({
  }),
  props: {
    item: Object,
    field: String,
    url: String,
    path: {
      type: String,
      default: "",
    },
  },
  /*watch: {
    item: {
      deep: true,
      handler(val) {
        //this.urlVideo = this.path;
      },
    },
    value: {
      immediate: true,
      handler(val) {
      },
    },
  },*/
  methods: {
    save(){
        if(this.item){
            let params = {};
            params[this.field] = this.value;
            this.$http.post(this.url + '/' + this.item.id, params, true).then((response) => {

            })
        }
    },
    haveVideo() {
      if (this.value) {
        if (this.getIdYoutube(this.value)) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    },
    getIdYoutube(url) {
      if (url) {
        const urlsplit = url.split(
          /^.*(youtu.be\/|v\/|embed\/|watch\?|youtube.com\/user\/[^#]*#([^\/]*?\/)*)\??v?=?([^#\&\?]*).*/
        );
        if (urlsplit[3]) {
          return urlsplit[3];
        }
      }
      return "";
    },
    obtenerUrlVideo() {
      const idYt = this.getIdYoutube(this.value);
      return (
        "https://www.youtube.com/embed/" +
        idYt +
        "?loop=1&modestbranding=1&autoplay=0&showinfo=0&iv_load_policy=3&rel=0&disablekb=1"
      );
    },
  },
};
</script>
