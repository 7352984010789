export default {
  data() {
    return {
      socketChat: null,
      callInProgress: false,
      receiveCallId: null,
      otherUserId: null,
      partnerVideo: null,
      showScreenShare: false,
      senderScreenShare: null,
      peerRef: null,
      dataChannel: null,
      userStream: null,
      screenShareTrack: null,
      openMic: false,
      disabledMic: true,
      audioTrack: null,
      userAudio: null,
    };
  },
  methods: {
    initVideoChat() {
      this.callInProgress = true;
      this.socketChat.on("offer", this.handleRecieveCall);

      this.socketChat.on("answer", this.handleAnswer);

      this.socketChat.on("ice-candidate", this.handleNewICECandidateMsg);
      this.socketChat.on("close-video-chat", this.closeVideoChat);
      this.callUser(this.otherUserId);
      this.enumerateDevices();
    },
    callUser(userID) {
      this.peerRef = this.createPeer(userID);
      this.dataChannel = this.peerRef.createDataChannel('mensajes_sistema');
    },
    unshareScreen() {
      this.stopScreenShareTrack();
      this.socketChat.emit("unshare-screen", this.otherUserId);
    },
    enumerateDevices() {
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        for (let device of devices) {
          if (device.deviceId !== "default" && device.kind === "audioinput") {
            this.disabledMic = false;
            break;
          }
        }
      });
    },
    createPeer(userID) {

      const peer = new RTCPeerConnection({
        iceServers: [
          {
            urls: "stun:stun.l.google.com:19302",
          },
          // {
          //   urls: "turn:numb.viagenie.ca",
          //   credential: "muazkh",
          //   username: "webrtc@live.com",
          // },
        ],
      });

      peer.onicecandidate = this.handleICECandidateEvent;
      peer.ontrack = this.handleTrackEvent;
      peer.onconnectionstatechange = this.handleConnectionStateChange;
      peer.onnegotiationneeded = () =>
        this.handleNegotiationNeededEvent(userID);
      return peer;
    },
    handleConnectionStateChange(e) {
      if (
        e.currentTarget.connectionState === "failed" ||
        e.currentTarget.connectionState === "disconnected"
      ) {
        this.resetPeer();
        this.callInProgress = false;
      }
    },
    handleNegotiationNeededEvent(userID) {
      this.peerRef
        .createOffer()
        .then((offer) => {
          return this.peerRef.setLocalDescription(offer);
        })
        .then(() => {
          const payload = {
            target: userID,
            caller: this.socketChat.id,
            sdp: this.peerRef.localDescription,
          };
          this.socketChat.emit("offer", payload);
        })
        .catch((e) => console.log(e));
    },

    handleRecieveCall(incoming) {
      const desc = new RTCSessionDescription(incoming.sdp);
      this.peerRef
        .setRemoteDescription(desc)
        .then(() => {
          return this.peerRef.createAnswer();
        })
        .then((answer) => {
          return this.peerRef.setLocalDescription(answer);
        })
        .then(() => {
          const payload = {
            target: incoming.caller,
            caller: this.socketChat.id,
            sdp: this.peerRef.localDescription,
          };
          this.socketChat.emit("answer", payload);
        });
    },

    handleAnswer(message) {

      const desc = new RTCSessionDescription(message.sdp);
      this.peerRef.setRemoteDescription(desc).catch((e) => {
        console.log(e);
      });
    },

    handleICECandidateEvent(e) {
      if (e.candidate) {
        const payload = {
          target: this.otherUserId,
          candidate: e.candidate,
        };
        this.socketChat.emit("ice-candidate", payload);
      }
    },

    handleNewICECandidateMsg(incoming) {
      const candidate = new RTCIceCandidate(incoming);

      this.peerRef.addIceCandidate(candidate).catch((e) => console.log(e));
    },

    handleTrackEvent(e) {
      if (e && e.streams) {
        if (e.track.kind == "audio") {
          this.$refs.partnerAudio.srcObject = e.streams[0];
        } else {
          this.$refs.partnerVideo.srcObject = e.streams[0];
          this.showScreenShare = true;
        }
      }
    },
    useMic() {
      if (this.userAudio) {
        this.openMic = !this.openMic;
        this.userAudio.track.enabled = this.openMic;
      } else {
        navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
          if (!this.userStream) {
            this.userStream = stream;
          }
          this.userAudio = this.peerRef.addTrack(
            this.audioTrack = stream.getTracks()[0],
            this.userStream
          );
          this.openMic = true;
        });
      }
    },

    closeChat() {
      this.resetPeer();
      this.callInProgress = false;
      this.socketChat.emit("finalize video chat", this.socketChat.id, this.otherUserId);
      this.offEventsSocket();

    },
    async resetPeer() {
      if (this.peerRef) {
        await this.peerRef.restartIce();
        await this.peerRef.close();
        this.peerRef = null;
      }
      this.stopMicroMedia();
      this.stopScreenShareTrack();
      this.dataChannel = null;
      this.userStream = null;
      this.userAudio = null;
      this.senderScreenShare = null;
    },
    stopScreenShareTrack() {
      if (this.screenShareTrack) {
        this.screenShareTrack.stop();
        this.screenShareTrack = null;
        if (this.peerRef) {
          this.dataChannel.send('hide_screen_share');
        }
      }
    },
    stopMicroMedia() {
      if (this.audioTrack) {
        this.audioTrack.stop();
        this.audioTrack = null;
        this.openMic = false;
      }
    },
    async closeVideoChat() {
      this.resetPeer();
      this.callInProgress = false;
      this.socketChat.emit('leave video chat', this.socketChat.id);
      this.offEventsSocket();


    },
    offEventsSocket() {
      this.socketChat.off('offer');
      this.socketChat.off('answer');
      this.socketChat.off('ice-candidate');
      this.socketChat.off('close-video-chat');
    },
    shareScreen() {
      navigator.mediaDevices
        .getDisplayMedia({ cursor: true })
        .then((stream) => {
          const screenTrack = stream.getTracks()[0];
          if (!this.userStream) {
            this.userStream = stream;
          }
          if (this.senderScreenShare) {
            this.dataChannel.send('show_screen_share');

            this.senderScreenShare.replaceTrack(screenTrack);
          } else {
            this.senderScreenShare = this.peerRef.addTrack(
              screenTrack,
              this.userStream
            );
          }

          screenTrack.onended = () => {
            this.screenShareTrack = null;
            this.dataChannel.send('hide_screen_share');
            this.socketChat.emit("unshare-screen", this.otherUserId);
          };
          this.screenShareTrack = screenTrack;
        });
    },
  },
};