import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'

import store from './store'
import http from '@/plugins/http'
// import printManager from '@/plugins/print-manager';
import auth from '@/plugins/auth'
import router from './router'
import './plugins/register-components'
import i18n from './plugins/i18n'
import './plugins/vee-validate'
import filters from './plugins/filters'
import Configuration from './plugins/configuration'
import appUtil from "@/plugins/app-utils.js";
//import  './plugins/db-firestore'

import { ValidationProvider, ValidationObserver } from 'vee-validate';



Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);


Vue.config.productionTip = false

//Vue.prototype.$http = HttpClient;
//Vue.prototype.$auth = new Auth();
Vue.use(http, { store });
//Vue.use(printManager);
Vue.use(auth, { store, router });
//Vue.use(configuration, { store });
Vue.prototype.$filters = filters;


import LocalStorage from './plugins/localStorage/local-storage';
import LokiAdapter from './plugins/localStorage/loki/loki';
const loki = require("lokijs");
const lfsa = require('./plugins/localStorage/loki/loki-indexed-adapter');

const adapterLk = new lfsa();
const dbLocal = new loki('chat.db', {
  adapter: adapterLk,
  autoload: true,
  autoloadCallback: databaseInitialize,
  autosave: true,
  autosaveInterval: 4000
});

const $sourceConfig = new Configuration(store);

const adapter = new LokiAdapter(dbLocal, store);
async function databaseInitialize() {

  adapter.databaseInitialize();

  const configurations = await adapter.getConfigurations();
  $sourceConfig.map(configurations);

  
  //adapter.deleteAll('orders');
  //adapter.deleteAll('cash_register_turn');
  //adapter.printAll('cash_register_turn')
  //adapter.deleteAll('product_variants');
  const installation = await adapter.getInstallation();
  store.dispatch('setInstallation', installation)

}

const db = new LocalStorage(adapter);

Vue.prototype.$db = db;
Vue.prototype.$sourceConfig = $sourceConfig;


import PosManager from './plugins/pos/pos-manager';
Vue.prototype.$pos = new PosManager(db, store, Vue.prototype.$http, filters);

const onlineHandler = () => {
  store.commit('online', true)
}

const offlineHandler = () => {
  store.commit('online', false)
}

let profile = localStorage.getItem('profile');
if(profile){
  try{
    profile = JSON.parse(profile);
    store.commit('setUser', profile);
  }catch(e){
    console.log(e)
  }
  
}
window.addEventListener('online', onlineHandler)
window.addEventListener('offline', offlineHandler)

/*
import * as Sentry from "@sentry/vue";
Sentry.init({
  dsn: "https://01e855ca19a5e4428c4564a74b829733@o157329.ingest.sentry.io/4506752390463488",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost"],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});*/

new Vue({
  vuetify,
  store,
  // auth,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')

String.prototype.hashCode = function () {
  if (Array.prototype.reduce) {
    return this.split("").reduce(function (a, b) { a = ((a << 5) - a) + b.charCodeAt(0); return a & a }, 0);
  }
  var hash = 0;
  if (this.length === 0) return hash;
  for (var i = 0; i < this.length; i++) {
    var character = this.charCodeAt(i);
    hash = ((hash << 5) - hash) + character;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
}
