<template>
  <div class="d-flex">
    <validation-provider
      v-slot="{ errors }"
      :name="label"
      :rules="rules"
      style="width: 100%"
    >
      <v-select
        :value="value"
        v-bind="{ ...config, ...$attrs }"
        :items="itemsList"
        :error-messages="errors"
        @input="inputEvt"
        v-on="$listeners"
        :label="$t(label) + labelRequired()"
        :item-text="'name'"
        :value-comparator="compare"
        return-object
      >
      </v-select>
    </validation-provider>
    <ng-help v-if="help" :text="help" />
  </div>
</template>
<script>
import AbstractField from "./AbstractField";
export default {
  name: "NgSelectOptions",
  mixins: [AbstractField],
  props: ["items"],
  created() {
    //this.items = this.options.items;
  },
  computed: {
    itemsList() {
      return this.items || this.options.items;
    },
   
  },
};
</script>
