<template>
<v-sheet  outlined class="mt-2">
  <v-expansion-panels dense>
    <v-expansion-panel dense>
      <v-expansion-panel-header>
        <slot name="title"></slot>
        </v-expansion-panel-header>
        <v-expansion-panel-content dense>
        <v-list-item>
        <v-list-item-content>
              <!--div class="text-overline mb-2">
                <slot name="title"></slot>
              </div-->
              <div>
                <slot name="body"></slot>
              </div>
            </v-list-item-content>

          </v-list-item>
      </v-expansion-panel-content>
      <!--v-card-actions>
        <v-btn  outlined  rounded text>
          Button
        </v-btn>
    </v-card-actions-->
    </v-expansion-panel>
    </v-expansion-panels>
  </v-sheet>
</template>
<script>
  export default {
    name: 'NgHelp',
    data(){
      return {
        dialog: false
      }
    },
    props: ['text']
 
  }
</script>
