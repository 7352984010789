<template>
  <div class="ng-logs pa-2 mt-3" v-if="item">
    <b>Historial</b>
    <div class="logs-content">
      <div v-if="item.created_at"> Fecha creación: {{$filters.datetime(item.created_at)}}</div>
      <div v-if="item.updated_at"> Fecha última modificación: {{$filters.datetime(item.updated_at)}}</div>
       <v-btn  small color="secondary" class="mt-2" @click="showMore" >
           <v-icon class="mr-2"  >mdi-magnify </v-icon> ver más
      </v-btn>

      <v-divider class="my-2 pt-2"></v-divider>
      <div>
        <div v-for="log in logs" class="mt-3" :key="log.id">
          <b>{{$filters.datetime(log.created_at)}}, </b> <span>{{log.user_name}}</span>
          <div>
            <small>{{log.text}}</small>
            <div class="ml-4 mt-2">
              <ng-json-pretty :json="parseJSON(log.changes)"></ng-json-pretty>
            </div>
          </div>
        </div>

      </div>

    </div>
  
  </div>
</template>
<script>
//import VueJsonPretty from 'vue-json-pretty';
//import 'vue-json-pretty/lib/styles.css';
  export default {
    name: 'NgLog',
    components: {  },
    data(){
      return {
        logs: []
      }
    },
    props: ['item', 'config'],
    methods: {
      parseJSON(text){
        return JSON.parse(text)
      },
      showMore(){
        this.$http.post('api/v1/admin/logs/list', { data: { object_id: this.item.id, object_type : this.config.model }, _paginate: { page: 1, max_rows: 1000}, _order : [ { field: 'created_at', direction: 'DESC'}]}, this, true, true).then(response => {
          this.logs = response.items;
        });
      }
    }
 
  }
</script>
<style>
.ng-logs{
  background-color: #eee;
}
.logs-content{
  font-size: 0.9em;
}
</style>
