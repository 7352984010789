<template>
  <validation-provider
    v-slot="{ errors }"
    :name="label"
    :rules="rules"
    style="width: 100%"
  >
    <div class="d-flex">
      <v-autocomplete
        :value="value"
        :loading="loadingSearch"
        v-bind="{ ...config, ...$attrs }"
        :items="itemsServer"
        :label="$t(label) + labelRequired()"
        item-text="name"
        :open-on-clear="true"
        :error-messages="errors"
        :search-input.sync="search"
        :no-filter="true"
        persistent-hint
        v-on="$listeners"
        @input="inputEvt"
       return-object
      ><!-- -->
      </v-autocomplete>
      <ng-help v-if="help" :text="help" />
      <slot></slot>
    </div>
  </validation-provider>
</template>
<script>
import AbstractField from "./AbstractField";
export default {
  name: "NgAuto",
  mixins: [AbstractField],
  data() {
    return {
      itemsServer: [],
      loadingSearch: false,
      search: null,
    };
  },
  props: ["url", "dependsOf"],
  watch: {
    search(val) {
      let txt = "";
      if (this.value && this.value.name && val == this.value.name) {
        return;
      }
      /*if (this.value && this.value.nombre) {
        txt = this.value.nombre;
      }*/
      if (
        val &&
        val !== this.value &&
        (!this.value || (this.value && val != this.value.name))
      ) {
        this.querySelectionsSearch(val);
      }
    },
    value: {
      deep: true,
      handler(){
        this.setInitialItemsFromValue();
      }
    }
  },
  mounted() {
    this.setInitialItemsFromValue();
  },
  methods: {
    setInitialItemsFromValue() {
      if (this.value && this.value.id && this.itemsServer.length == 0) {
        this.itemsServer = [JSON.parse(JSON.stringify(this.value))];
      }
      //this.$forceUpdate();
    },
    querySelectionsSearch(v) {
      /*if (v.id) {
        return;
      }*/
      this.loadingSearch = true;
      const params = {};
      if (this.dependsOf) {
        for (const index in this.dependsOf) {
          params[index] = this.dependsOf[index];
        }
      }
      if (v) {
        params.name = v;
      }
      //this.getUrlQuery(params)
      this.$http
        .get(this.getUrlQuery(), params)
        .then((data) => {
          this.itemsServer = data.items;
        })
        .catch(() => {
          this.loadingSearch = false;
        })
        .finally(() => {
          this.loadingSearch = false;
        });
    }
  },
};
</script>
